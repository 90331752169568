"use strict";
/**
 * @description    函数节流 节流，就是指连续触发事件但是在 n 秒中只执行一次函数。节流会稀释函数的执行频率。
 * @param handler  参数 函数
 * @param wait     参数 等待的时间
 * @returns 返回新函数
 */
Object.defineProperty(exports, "__esModule", { value: true });
function throttle(handler, wait) {
    var lastTime = 0;
    return function () {
        var nowTime = new Date().getTime();
        if (nowTime - lastTime > wait) {
            handler.apply(this, arguments);
            lastTime = nowTime;
        }
    };
}
exports.default = throttle;
