import { createStore,applyMiddleware,combineReducers } from 'redux';
// import thunk from 'redux-thunk';

const initialState = {
  str:"hello word"
}

const menuKey = (state = initialState, action) => {
    switch (action.type) {
        case "menu":
            return action.data
        default:
            return {
                openKeys: [],
                selectedKeys: []
            };
    }
}

const reducer2 = (state = initialState, action) => {
    switch (action.type) {
        case "test":
            return action
        default:
            return initialState;
    }
}

//生成store对象
// const store = createStore(reducer,applyMiddleware(thunk));//内部会第一次调用reducer函数，得到初始state 

//combineReducers合并多个reducer 单个不用合并直接createStore就可以了
const reducer = combineReducers({
    menuKey:menuKey,
    reducer2:reducer2
})

let store = createStore(reducer);//内部会第一次调用reducer函数，得到初始state 想要在view每次修改都刷新页面 需要subscribe在页面加载完成的时候执行(componentDidMount里面执行)监听
export default store;