"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var initRequest_1 = require("./initRequest");
var fetch_1 = require("./fetch");
var default_1 = /** @class */ (function () {
    function default_1(options) {
        var initRequest = new initRequest_1.default(new fetch_1.default(options));
        return {
            initRequest: initRequest,
        };
    }
    return default_1;
}());
exports.default = default_1;
