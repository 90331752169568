"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var initRequest = /** @class */ (function () {
    function initRequest(fetch) {
        this.fetch = null;
        this._upload = function () {
            return this._customRequest('upload');
        };
        this.fetch = fetch;
        this.des = this.des.bind(this);
        this.setEnv = this.setEnv.bind(this);
        this._get = this._get.bind(this);
        this._post = this._post.bind(this);
        this._upload = this._upload.bind(this);
        this._customRequest = this._customRequest.bind(this);
    }
    initRequest.prototype.setEnv = function (env, headers) {
        this.fetch.setEnv(env, headers);
    };
    initRequest.prototype.des = function () {
        return {
            get: this._get,
            post: this._post,
            upload: this._upload
        };
    };
    initRequest.prototype._get = function (url) {
        return this._customRequest("get", url);
    };
    initRequest.prototype._post = function (url) {
        return this._customRequest("post", url);
    };
    initRequest.prototype._customRequest = function (type, url) {
        var _this = this;
        if (type === 'get') {
            return function (params, headers) {
                if (params === void 0) { params = {}; }
                if (headers === void 0) { headers = {}; }
                return _this.fetch.get(url, params, headers);
            };
        }
        if (type === 'post') {
            return function (params, body, headers) {
                if (params === void 0) { params = {}; }
                if (body === void 0) { body = {}; }
                if (headers === void 0) { headers = {}; }
                var i = 0;
                var u = url;
                for (var key in params) {
                    if (i === 0) {
                        i = 1;
                        u += "?" + key + "=" + params[key];
                    }
                    else {
                        u += "&" + key + "=" + params[key];
                    }
                }
                return _this.fetch.post(u, body, headers);
            };
        }
        if (type === 'upload') {
            return function (form, option) {
                return _this.fetch.upload(form, option);
            };
        }
    };
    return initRequest;
}());
exports.default = initRequest;
