import React, { useState, useEffect, useRef, useCallback, useImperativeHandle, forwardRef } from 'react';
import './index.scss';

import {
    DatePicker, Tabs, Popover, Select, Input, message
} from 'antd';

import debounce from 'lodash/debounce';

import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/date-picker/locale/zh_CN';

import { useHistory } from 'react-router-dom'
import { forIn } from 'lodash';

import { cookie, getQueryString } from '../../../approach/base';
import { string } from 'prop-types';

const { TabPane } = Tabs;

function Search(props: any, ref: any) {
    
    const [ searchAuto, setSearchAuto ] = useState(false);
    const [ keyCity, setKeyCity ] = useState("");
    const [ showCity, setShowCity ] = useState(0);
    
    const [ keySearch, setKeySearch ] = useState("");
    const [ showKeySearch, setShowKeySearch ] = useState(0);

    const [ startTime, setStartTime ] = useState("");
    const [ endTime, setEndTime ] = useState("");

    const [ keySearchList, setKeySearchList ] = useState({
        currentCityModel:{
            hotelSearchList:[],
            modelSearchList:[]
        }
    });
    const [ newHotelList, setNewHotelList ] = useState([]);

    const [ cityList, setCityList ] = useState<{
        China:{
            CityList:any[]
        },
        OverSeas:any[]
    }>({
        China:{CityList:[]},
        OverSeas:[]
    });

    const [ brand, setBrand ] = useState<any[]>([]);
    const [ hotPointAndXZQ, setHotPointAndXZQ ] = useState<{
        JiChang:any[],
        JingDian:any[],
        Metro:any[],
        QiTa:any[],
        ShangQuan:any[],
        XZQ:any[],
        ZhuanTi:any[],
    }>({
        JiChang:[],
        JingDian:[],
        Metro:[],
        QiTa:[],
        ShangQuan:[],
        XZQ:[],
        ZhuanTi:[],
    });
    const [ activityList, setActivityList ] = useState<any[]>([]);
    const [ others, setOthers ] = useState<any[]>([]);

    const [ formData, setFormData ] = useState<{
        cityName: string,//城市name
        cityId: string,//城市ID
        checkintime: string,//到店日期
        days: number,//入住天数
        pagesize: number,//页数
        pageindex: number,//页码
        activityJson: any[],//活动数组
        labeljson: any[],//其他筛选数组
        xzId: string,//行政区ID
        hotelSortRule: number,//排序规则 1推荐2价格最高3价格最低4评分最好5距离排序
        keySearchJson: string,//关键字json
        hotPointId: string,//商圈id
        brandjson: any[],//品牌数组
        hotelcode: string,//酒店编号
        facilityjson: any[],//设施数组
        userId: string,//用户信息
        screeningJson: any[],
        keyValue: string,//关键字
        crtLng: string,//城市中心点或当前用户位置经度
        crtLat: string//城市中心点或当前用户位置纬度
    }>({
        cityName: "",
        cityId: "",
        checkintime: "",
        days: 1,
        pagesize: 10,
        pageindex: 1,
        activityJson: [],
        labeljson: [],
        xzId: "",
        hotelSortRule: 1,
        keySearchJson: "",
        hotPointId: "",
        brandjson: [],
        hotelcode: "",
        facilityjson: [],
        userId: JSON.parse(decodeURIComponent(`${cookie.get("userInfo")}`))?JSON.parse(decodeURIComponent(`${cookie.get("userInfo")}`)).Card_no:"",
        screeningJson: [],
        keyValue: "",
        crtLng: "",
        crtLat: ""
    })

    const history = useHistory();

    const { getCityList, getKeySearch, getBrandInfoSearch, getHotPointAndXZQ, getActivityList, getAllLabelType, getNewHotelList, getCityByLocation } = window.$axios.hotelList;

    useEffect(() => {
        document.addEventListener('click', (e:any) => {
            _body(e);
        });
        _getInit();
        _getCity();
    }, [])

    useEffect(() => {
        props.changeData(formData);
        document.addEventListener('click', (e:any) => {
            _body(e);
        });
    }, [formData])

    useEffect(() => {
        if(formData.cityId == ""){
            return
        }
        history.push(`/hotelList?cityName=${formData.cityName}&cityId=${formData.cityId}&checkintime=${startTime}&checkouttime=${endTime}&days=${formData.days}&keyValue=${formData.keyValue}`)
        _getInit2();
    }, [formData.cityId])

    //父组件调用子组件的方法 放在useImperativeHandle里面 可以写多个函数
    useImperativeHandle(ref, () => {
        return {
            _change(data: any) {
                setFormData({...formData,...data});
            },
        };
    });

    const _body = (e:any) => {
        if(e.target && e.target.className == 'ant-tabs-tab-btn'){
            return;
        }
        setShowCity(0);
        setKeyCity(formData.cityName);
        setShowKeySearch(0);
    }
 
    const _getCity = () => {
        getCityByLocation().then((res:any) => {
            let newForm = {
                cityName: getQueryString("cityName") || res.data?.cityname || "上海市",
                cityId: getQueryString("cityId") || res.data?.cityid || "226",
                checkintime: _getTime(getQueryString("checkintime") || new Date().getTime()),
                checkouttime: _getTime(getQueryString("checkouttime") || new Date(new Date().getTime() + 24*60*60*1000).getTime()),
                days: Number(getQueryString("days")) || formData.days,
                keyValue: getQueryString("keyValue") || formData.keyValue,
            }
            setStartTime(newForm.checkintime);
            setEndTime(newForm.checkouttime);
            setKeyCity(newForm.cityName);
            setFormData({...formData,...newForm});
        }).catch((err:any) => {
            let newForm = {
                cityName: "上海市",
                cityId: "226",
                checkintime: _getTime(getQueryString("checkintime") || new Date().getTime()),
                checkouttime: _getTime(getQueryString("checkouttime") || new Date(new Date().getTime() + 24*60*60*1000).getTime()),
                days: Number(getQueryString("days")) || formData.days,
                keyValue: getQueryString("keyValue") || formData.keyValue,
            }
            setStartTime(newForm.checkintime);
            setEndTime(newForm.checkouttime);
            setKeyCity(newForm.cityName);
            setFormData({...formData,...newForm});
        })
    }

    const _getInit = async() => {
        await getCityList().then((res:any) => {
            if(res.data){
                setCityList(res.data);
            }
        }).catch((err:any) => {

        })
        await getBrandInfoSearch().then((res:any) => {
            if(res.data.length > 0){
                setBrand(res.data);
            }
        }).catch((err:any) => {

        })
        await getActivityList().then((res:any) => {
            if(res.data.length > 0){
                setActivityList(res.data);
            }
        }).catch((err:any) => {

        })
        await getNewHotelList({pagesize:1,pageindex:0}).then((res:any) => {
            if(res.data){
                setNewHotelList(res.data.items);
            }
        }).catch((err:any) => {

        })
    }

    const _getInit2 = async() => {
        await getHotPointAndXZQ({cityId:formData.cityId}).then((res:any) => {
            if(res.data){
                setHotPointAndXZQ(res.data);
            }
        }).catch((err:any) => {

        })
        await getAllLabelType({cityId:formData.cityId}).then((res:any) => {
            if(res.data.length > 0){
                setOthers(res.data);
            }
        }).catch((err:any) => {

        })
    }

    const _getKeySearch = debounce((key:string) => {
        if(key.length == 0){
            setKeySearchList({
                currentCityModel:{
                    hotelSearchList:[],
                    modelSearchList:[]
                }
            });
            return
        }
        getKeySearch({
            cityId:formData.cityId,
            keyValue:key
        }).then((res:any) => {
            if(res.data){
                setKeySearchList(res.data);
            }else{
                message.error(res.msg);
            }
        }).catch((err:any) => {
            
        })
    },800)

    const _localStorage = (item:any) => {
        let historyCity = JSON.parse(cookie.get("historyCity") || "[]");

        if (historyCity.map((e:any) => e.cityId).includes(item.cityId)) {
            return 
        }
        historyCity.push(item);
        cookie.set("historyCity", JSON.stringify(historyCity), 30);
    }


    const _getTime = (time:any) => {
        let newTime = new Date(time);
        let month = (newTime.getMonth() + 1)>9?(newTime.getMonth() + 1):"0" + (newTime.getMonth() + 1);
        let day = newTime.getDate()>9?newTime.getDate():"0" + newTime.getDate();
        return newTime.getFullYear() + "-" + month + "-" + day;
    }

    return (
        <React.Fragment>
            <div className="search">
                <div className="search-top">
                    <div className="search-content ub ub-space-between max-width">
                        <div className="search-left ub">
                            <div>
                                <div className="color999">目的地</div>
                                <div>
                                    <Input value={keyCity} placeholder="请选择城市" 
                                        onChange={(e) => {
                                            setShowCity(2);
                                            setKeyCity(e.target.value);
                                        }}
                                        onClick={(e) => {
                                            setShowCity(1);
                                            e.nativeEvent.stopImmediatePropagation();
                                        }}
                                        onBlur={() => {
                                            // setShowCity(0);
                                            // setKeyCity(formData.cityName);
                                        }}
                                    />
                                </div>
                                {
                                    showCity == 2 && <div className="cityList" onClick={(e) => {e.nativeEvent.stopImmediatePropagation();}}>
                                        {
                                            cityList.China.CityList.filter(e => e.Name.indexOf(keyCity)>-1 || e.FullSpell.indexOf(keyCity)>-1).map(item => {
                                                return <div className="text" onClick={() => {
                                                    setKeyCity(item.Name);
                                                    setFormData({...formData,...{cityId:item.ID,cityName:item.Name}});
                                                    setShowCity(0);
                                                    _localStorage({cityId:item.ID,cityName:item.Name});
                                                }}><span>{item.CityAreaName.split(",")[0]}</span><span>{item.CityAreaName.split(",")[1]}</span></div>
                                            })
                                        }
                                        {
                                            cityList.OverSeas.map(Item => {
                                                return Item.CityList.filter((e:any) => e.Name.indexOf(keyCity)>-1 || e.FullSpell.indexOf(keyCity)>-1).map((item:any) => {
                                                    return <div className="text" onClick={() => {
                                                        setKeyCity(item.Name);
                                                        setFormData({...formData,...{cityId:item.ID,cityName:item.Name}});
                                                        setShowCity(0);
                                                        _localStorage({cityId:item.ID,cityName:item.Name});
                                                    }}><span>{item.Name}</span><span>{Item.Name}</span></div>
                                                })
                                            })
                                        }
                                    </div>
                                }
                                {
                                    showCity == 1 && <div className="search-city" onClick={(e) => {e.nativeEvent.stopImmediatePropagation();}}>
                                        <div>请选择城市或输入城市名称</div>
                                        <div>
                                            <div className="history">
                                                <div>搜索历史</div>
                                                <div>
                                                    <div className="screen-type-list ub">
                                                        {
                                                            JSON.parse(cookie.get("historyCity") || "[]").map((item:any) => {
                                                                return <div className="text" onClick={() => {
                                                                    setKeyCity(item.cityName);
                                                                    setFormData({...formData,...{cityId:item.cityId,cityName:item.cityName}});
                                                                    setShowCity(0);
                                                                    _localStorage({cityId:item.cityId,cityName:item.cityName});
                                                                }}>{item.cityName}</div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                            <Tabs defaultActiveKey="0">
                                                <TabPane tab={"国内城市"} key="0">
                                                    <Tabs defaultActiveKey="a0">
                                                        {
                                                            ["热门城市","ABCD","EFGH","IJKL","MNOP","QRST","UVWX","YZ"].map((item,index) => {
                                                                return <TabPane tab={item} key={"a"+index}>
                                                                    <div className="screen-type-list ub">
                                                                        {
                                                                            item == "热门城市"?
                                                                            cityList.China.CityList.filter(e => e.IsHotCity == 1).map((city:any) => {
                                                                                return <div className="text" onClick={() => {
                                                                                    setKeyCity(city.Name);
                                                                                    setFormData({...formData,...{cityId:city.ID,cityName:city.Name}});
                                                                                    setShowCity(0);
                                                                                    _localStorage({cityId:city.ID,cityName:city.Name});
                                                                                }}>{city.Name}</div>
                                                                            }):
                                                                            cityList.China.CityList.filter(e => item.split("").includes(e.ShortName.substring(0,1))).map((city:any) => {
                                                                                return <div className="text" onClick={() => {
                                                                                    setKeyCity(city.Name);
                                                                                    setFormData({...formData,...{cityId:city.ID,cityName:city.Name}});
                                                                                    setShowCity(0);
                                                                                    _localStorage({cityId:city.ID,cityName:city.Name});
                                                                                }}>{city.Name}</div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                </TabPane> 
                                                            })
                                                        }
                                                    </Tabs>
                                                </TabPane> 
                                                <TabPane tab={"国外城市"} key="1">
                                                    <Tabs defaultActiveKey="b0">
                                                        {
                                                            cityList.OverSeas.map((item,index) => {
                                                                return <TabPane tab={item.Name} key={"b"+index}>
                                                                    <div className="screen-type-list ub">
                                                                        {
                                                                            item.CityList.map((city:any) => {
                                                                                return <div className="text" onClick={() => {
                                                                                    setKeyCity(city.Name);
                                                                                    setFormData({...formData,...{cityId:city.ID,cityName:city.Name}});
                                                                                    setShowCity(0);
                                                                                    _localStorage({cityId:city.ID,cityName:city.Name});
                                                                                }}>{city.Name}</div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                    
                                                                </TabPane> 
                                                            })
                                                        }
                                                    </Tabs>
                                                </TabPane> 
                                            </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                }
                                
                            </div>
                            <div>
                                <div className="color999">入住</div>
                                <div onClick={(e) => {e.nativeEvent.stopImmediatePropagation();}}>
                                    <DatePicker
                                        className="datePicker"
                                        locale={locale}
                                        value={moment(startTime)}
                                        disabledDate={(current:any) => {
                                            return current < moment().subtract(1, 'days');  
                                        }}
                                        onChange={(date,dateString) => {
                                            setStartTime(dateString);
                                            if(moment(dateString).valueOf() >= moment(endTime).valueOf()){
                                                setEndTime(_getTime(moment(dateString).valueOf() + 24*60*60*1000));
                                            }
                                            setFormData({...formData,...{
                                                checkintime:dateString,
                                                days: (new Date(_getTime(moment(dateString).valueOf() + 24*60*60*1000)).getTime() - new Date(dateString).getTime())/(24*60*60*1000)
                                            }});
                                        }}
                                        format="YYYY-MM-DD"
                                        bordered={false}
                                    />
                                </div>
                            </div>
                            <div>-{(new Date(endTime).getTime() - new Date(startTime).getTime())/(24*60*60*1000)}晚 -</div>
                            <div>
                                <div className="color999">离店</div>
                                <div onClick={(e) => {e.nativeEvent.stopImmediatePropagation();}}>
                                    <DatePicker
                                        className="datePicker"
                                        locale={locale}
                                        value={moment(endTime)}
                                        disabledDate={(current:any) => {
                                            return current.valueOf() <= moment(startTime).valueOf(); 
                                        }}
                                        onChange={(date,dateString) => {
                                            setEndTime(dateString);
                                            setFormData({...formData,...{
                                                days: (new Date(dateString).getTime() - new Date(startTime).getTime())/(24*60*60*1000)
                                            }});
                                        }}
                                        format="YYYY-MM-DD"
                                        bordered={false}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="color999">关键字选填</div>
                                <div>
                                    <Input value={formData.keyValue} placeholder="请输入酒店名/品牌/地标" 
                                        onChange={(e) => {
                                            setShowKeySearch(2);
                                            _getKeySearch(e.target.value);
                                            setFormData({...formData,...{keyValue:e.target.value}});
                                        }} 
                                        onClick={(e) => {
                                            setShowKeySearch(1);
                                            e.nativeEvent.stopImmediatePropagation();
                                        }}
                                        onBlur={() => {
                                            // setShowKeySearch(0);
                                        }}
                                    />
                                </div>
                                {
                                    showKeySearch == 2 && <div className="cityList" onClick={(e) => {e.nativeEvent.stopImmediatePropagation();}}>
                                        {
                                            keySearchList.currentCityModel.hotelSearchList.map((item:any) => {
                                                return <div className="text ub ub-space-between" onClick={() => {history.push(`/hotelDetail?hotelId=${item.hotelCode}&price=${item.price}`);}}><span style={{width: "80%"}}>{item.hotelName}</span><span>酒店</span></div>
                                            })
                                        }
                                        {
                                            keySearchList.currentCityModel.modelSearchList.map((item:any) => {
                                                return <div className="text ub ub-space-between" onClick={() => {
                                                    setFormData({...formData,...{keyValue:item.businessName}});
                                                    setShowKeySearch(0);
                                                }}><span style={{width: "80%"}}>{item.businessName}</span><span>地标</span></div>
                                            })
                                        }
                                    </div>
                                }
                                {
                                    showKeySearch == 1 && <div className="cityList" onClick={(e) => {e.nativeEvent.stopImmediatePropagation();}}>
                                        <div className="text ub ub-space-between" style={{fontWeight:600}}>新店特惠</div>
                                        {
                                            newHotelList.map((item:any) => {
                                                return <div className="text ub ub-space-between" onClick={() => {history.push(`/hotelDetail?hotelId=${item.id}&price=${item.price}`);}}><span style={{width: "80%"}}>{item.name}</span><span>酒店</span></div>
                                            })
                                        }
                                    </div>
                                }
                            </div>
                            <div onClick={() => {
                                // props.changeData(formData);
                                setFormData({...formData,
                                    activityJson: [],
                                    facilityjson: [],
                                    labeljson: [],
                                    brandjson: [],
                                    screeningJson:[]
                                })
                            }}>搜索</div>
                        </div>
                        <div className="search-right" onClick={() => {
                            window.open(`https://998.com/HotelMap?cityId=${formData.cityId}&cityText=${formData.cityName}`)
                        }}><img src={require("../../../assets/images/map.png")} alt="" />地图预订</div>
                    </div>
                </div>
                <div className="search-center">
                    <div className={`search-screen max-width ${searchAuto?"heightAuto":""}`}>
                        <div className="search-list ub">
                            <div className="screen-left">酒店品牌</div>
                            <div className="screen-right">
                                <Tabs defaultActiveKey="0" onChange={(activeKey) => {
                                    if (activeKey == "0") {
                                        setFormData({...formData,...{brandjson:[]}});
                                    }
                                }}>
                                        <TabPane tab={"不限"} key="0">
                                        
                                        </TabPane> 
                                    {
                                        brand.map(item => {
                                            return <React.Fragment>
                                                <TabPane tab={item.HotelLevelName} key={item.HotelLevel}>
                                                    <div className="screen-type-list ub">
                                                        {/* <div className="text actived">全部</div> */}
                                                        {
                                                            item.BrandInfo.map((BrandInfo:any) => {
                                                                return <div className={`text ${formData.brandjson.map(e => e.HotelBrand).includes(BrandInfo.HotelBrand)?"actived":""}`} onClick={() => {
                                                                    let newBrandjson = formData.brandjson;
                                                                    if(formData.brandjson.map(e => e.HotelBrand).includes(BrandInfo.HotelBrand)){
                                                                        let index = formData.brandjson.map(e => e.HotelBrand).indexOf(BrandInfo.HotelBrand);
                                                                        formData.brandjson.splice(index,1);
                                                                    }else{
                                                                        newBrandjson.push({HotelBrand:BrandInfo.HotelBrand,HotelBrandName:BrandInfo.HotelBrandName});
                                                                    }
                                                                    setFormData({...formData,...{brandjson:newBrandjson}});
                                                                }}>{BrandInfo.HotelBrandName}</div>
                                                            })
                                                        }
                                                    </div>
                                                </TabPane>    
                                            </React.Fragment>
                                        })
                                    }
                                </Tabs>
                            </div>
                        </div>
                        <div className="search-list ub">
                            <div className="screen-left">位置/地标</div>
                            <div className="screen-right">
                                <Tabs defaultActiveKey="0" onChange={(activeKey) => {
                                    if (activeKey == "0") {
                                        setFormData({...formData,...{facilityjson:[]}});
                                    }
                                }}>
                                        <TabPane tab={"不限"} key="0">
                                        
                                        </TabPane> 
                                        {
                                            Object.keys(hotPointAndXZQ).map(key => {
                                                let arr = {JiChang:"机场/车站",
                                                    JingDian:"景点/场馆",
                                                    Metro:"地铁/干道",
                                                    QiTa:"其他位置",
                                                    ShangQuan:"商圈/中心",
                                                    XZQ:"行政区",
                                                    ZhuanTi:"专题",}
                                                if (key != "Metro") {
                                                    return <React.Fragment>
                                                        <TabPane tab={arr[key]} key={key}>
                                                            <div className="screen-type-list ub">
                                                                {
                                                                    hotPointAndXZQ[key]?.map((item:any) => {
                                                                        return <div className={`text ${formData.facilityjson.map(e => e.Id || e.XZId).includes(item.Id || item.XZId)?"actived":""}`} onClick={() => {
                                                                            let newBrandjson = [];
                                                                            if(formData.facilityjson.map(e => e.Id || e.XZId).includes(item.Id || item.XZId)){
                                                                                let index = formData.facilityjson.map(e => e.Id || e.XZId).indexOf(item.Id || item.XZId);
                                                                                formData.facilityjson.splice(index,1);
                                                                            }else{
                                                                                newBrandjson.push(item);
                                                                            }
                                                                            setFormData({...formData,...{facilityjson:newBrandjson}});
                                                                        }}>{item.Name || item.XZName}</div>
                                                                    })
                                                                }
                                                            </div>
                                                        </TabPane>    
                                                    </React.Fragment>
                                                }else{
                                                    return <React.Fragment>
                                                        <TabPane tab={arr[key]} key={key}>
                                                            <div style={{width:"1020px"}}>
                                                                <Tabs defaultActiveKey="line0" tabPosition="top">
                                                                    {
                                                                        hotPointAndXZQ[key]?.map((Item:any,index) => {
                                                                            return <TabPane tab={Item.Name} key={"line"+index}>
                                                                                <div className="screen-type-list ub">
                                                                                    {
                                                                                        Item.Point.map((item:any) => {
                                                                                            return <div className={`text ${formData.facilityjson.map(e => e.Id || e.XZId).includes(item.Id || item.XZId)?"actived":""}`} onClick={() => {
                                                                                                let newBrandjson = [];
                                                                                                if(formData.facilityjson.map(e => e.Id || e.XZId).includes(item.Id || item.XZId)){
                                                                                                    let index = formData.facilityjson.map(e => e.Id || e.XZId).indexOf(item.Id || item.XZId);
                                                                                                    formData.facilityjson.splice(index,1);
                                                                                                }else{
                                                                                                    newBrandjson.push(item);
                                                                                                }
                                                                                                setFormData({...formData,...{facilityjson:newBrandjson}});
                                                                                            }}>{item.Name || item.XZName}</div>
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </TabPane> 
                                                                        })
                                                                    }
                                                                </Tabs>
                                                            </div>
                                                            

                                                            
                                                        </TabPane>    
                                                    </React.Fragment>
                                                }
                                            })
                                        }
                                </Tabs>
                            </div>
                        </div>
                        <div className="search-list ub">
                            <div className="screen-left">酒店促销</div>
                            <div className="screen-right">
                                <div className="screen-type-list ub">
                                    <div className={`text ${formData.activityJson.length == 0?"actived":""}`} onClick={() => {
                                        setFormData({...formData,...{activityJson:[]}})
                                    }}>不限</div>
                                    {
                                        activityList.map((item:any) => {
                                            return <div className={`text ${formData.activityJson.map(e => e.activityCode).includes(item.activityCode)?"actived":""}`} onClick={() => {
                                                let newBrandjson = formData.activityJson;
                                                if(formData.activityJson.map(e => e.activityCode).includes(item.activityCode)){
                                                    let index = formData.activityJson.map(e => e.activityCode).indexOf(item.activityCode);
                                                    formData.activityJson.splice(index,1)
                                                }else{
                                                    newBrandjson.push(item);
                                                }
                                                setFormData({...formData,...{activityJson:newBrandjson}})
                                            }}>{item.activityName}</div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="search-list ub">
                            <div className="screen-left">其他筛选</div>
                            <div className="screen-right">
                                <Tabs defaultActiveKey="0" onChange={(activeKey) => {
                                    if (activeKey == "0") {
                                        setFormData({...formData,...{labeljson:[]}});
                                    }
                                }}>
                                        <TabPane tab={"不限"} key="0">
                                        
                                        </TabPane> 
                                    {
                                        others.map(item => {
                                            return <React.Fragment>
                                                <TabPane tab={item.ParentLable.LabelName} key={item.ParentLable.LabelKeyID}>
                                                    <div className="screen-type-list ub">
                                                        {/* <div className="text actived">全部</div> */}
                                                        {
                                                            item.ChildLableList.map((ChildLableList:any) => {
                                                                return <div className={`text ${formData.labeljson.map(e => e.LabelKeyID).includes(ChildLableList.LabelKeyID)?"actived":""}`} onClick={() => {
                                                                    let newBrandjson = formData.labeljson;
                                                                    if(formData.labeljson.map(e => e.LabelKeyID).includes(ChildLableList.LabelKeyID)){
                                                                        let index = formData.labeljson.map(e => e.LabelKeyID).indexOf(ChildLableList.LabelKeyID);
                                                                        formData.labeljson.splice(index,1);
                                                                    }else{
                                                                        newBrandjson.push(ChildLableList);
                                                                    }
                                                                    setFormData({...formData,...{labeljson:newBrandjson}});
                                                                }}>{ChildLableList.LabelName}</div>
                                                            })
                                                        }
                                                    </div>
                                                </TabPane>    
                                            </React.Fragment>
                                        })
                                    }
                                </Tabs>
                            </div>
                        </div>
                    </div>
                    <div className="search-btn" onClick={() => {setSearchAuto(!searchAuto)}}>{searchAuto?"收起筛选":"展开筛选"}</div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default forwardRef(Search);