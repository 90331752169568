import React, { useState, useEffect, useRef, useCallback, useImperativeHandle, forwardRef } from 'react';

import {
    Rate, Pagination
} from 'antd';

function Comment(props: any, ref: any) {

    const [ params, setParams ] = useState({
        pageSize: 10,
        pageIndex: 1,
    })

    const [ comments, setComments ] = useState({
        comments: [],
        currentPage: 1,
        score: {
            AvgPoint: 0,
            labelInfo: []
        },
        totalItems: 0,
        totalPage: 0
    });

    const commentRef:any = useRef();

    const { getComments } = window.$axios.hotelDetail;

    useEffect(() => {
        
    }, [])

    useEffect(() => {
        _getComments();
    }, [params])

    //父组件调用子组件的方法 放在useImperativeHandle里面 可以写多个函数
    useImperativeHandle(ref, () => {
        return {
            _scoll() {
                return commentRef.current.offsetTop;
            },
        };
    });

    const _getComments = () => {
        getComments({
            hotelId: props.propsData.hotelCode,
            ...params
        }).then((res: any) => {
            setComments(res.data)
        }).catch((err: any) => {
            console.log(err)
        })
    }

    const _comment = () => {
        return <div className="comment" ref={commentRef}>
            <div className="title">酒店点评</div>
            <div className="score ub ub-align-center">
                <div>{comments.score.AvgPoint}</div>
                <div>/5分</div>
                <div>源自 {comments.totalItems} 位客人点评</div>
            </div>
            <div className="category ub">
                {
                    comments.score.labelInfo.map((item:any,index:number) => {
                        return <div key={index}>{item.LabelName}</div>
                    })
                }
            </div>
            <div className="comment-lists">
                {
                    comments.comments && comments.comments.map((item:any,index:number) => {
                        return <div className="comment-list ub" key={index}>
                            <div className="left">
                                <div><img src={require("../../../../assets/images/hotelDetail/avatar.png")} alt="" /></div>
                                <div>{item.userTitle}</div>
                                <div>{item.userName}</div>
                            </div>
                            <div className="right">
                                <div><Rate disabled allowHalf value={Number(item.score)} style={{ fontSize: 16, color: "#FFD51A" }} /></div>
                                <div>{item.content}</div>
                                <div>{item.date}</div>
                                {
                                    item.replyContent && <React.Fragment>
                                        <div>[酒店回复]：{item.replyContent}</div>
                                        <div>{item.replyDate}</div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    })
                }
                
            </div>
            <div className="comment-pagination">
                <Pagination showSizeChanger={false} current={Number(comments.currentPage)} total={Number(comments.totalItems)} onChange={(page, pageSize) => {
                    setParams({...params,...{pageIndex:page}})
                }}/>
            </div>
        </div>
    }

    return (
        <React.Fragment>
            {_comment()}
        </React.Fragment>
    )
}
export default forwardRef(Comment);