"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// 获取hash后的search
//用于查询reactRouter的hashRouter模式下获取search 
function default_1(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var search = '';
    var hash = window.location.hash;
    var hashArr = hash.split('?');
    if (hashArr.length == 2) {
        search = hashArr[1];
    }
    var r = search.match(reg);
    if (r != null)
        return unescape(r[2]);
    return null;
}
exports.default = default_1;
;
