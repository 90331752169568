import React, { useState, useEffect, useRef, useCallback, useImperativeHandle, forwardRef } from 'react';
import './index.scss';

import {
    CaretUpOutlined,
    CaretDownOutlined
} from '@ant-design/icons';

import {
    Rate, Pagination
} from 'antd';

import { useHistory } from 'react-router-dom'
import {Map, Marker, CustomOverlay} from 'react-bmapgl';
import { cookie } from 'approach/base';

function HotelList(props:any, ref:any) {

    const [ hotelStoryList, setHotelStoryList ] = useState(JSON.parse(cookie.get("hotelStory") || "[]"));

    const [ formData, setFormData ] = useState<{
        pageindex:number,
        cityId: string,
        cityName: string,
        activityJson: any[],//活动数组
        facilityjson: any[],//位置数组
        labeljson: any[],//其他筛选数组
        brandjson: any[],//品牌数组
        screeningJson: any[],//品牌数组
    }>({
        pageindex:1,
        cityId: "",
        cityName: "",
        activityJson: [],
        facilityjson: [],
        labeljson: [],
        brandjson: [],
        screeningJson:[]
    });

    const [ hotelList, setHotelList ] = useState({
        items:[],
        currentPage: 1,
        totalPage: 0
    })

    const [ crt, setCrt ] = useState({
        latitude: 39.928216,
        longitude: 116.402544
    })

    const [ hotelSortRule, setHotelSortRule ] = useState(1);

    const mapRef:any = useRef();
    const history = useHistory();
    
    const { getHotelList } = window.$axios.hotelList;

    useEffect(() => {
        _getHotelList();
    }, [formData])

    useEffect(() => {
        setFormData({...formData,...{hotelSortRule:hotelSortRule}});
    }, [hotelSortRule])

    //父组件调用子组件的方法 放在useImperativeHandle里面 可以写多个函数
    useImperativeHandle(ref, () => {
        return {
            _change(data: any) {
                setFormData({...formData,...data});
            },
        };
    });

    const _getHotelList = () => {
        let newData = {...formData,...{
            activityJson:JSON.stringify(formData.activityJson),
            brandjson:JSON.stringify(formData.brandjson.map(e => e.HotelBrand)),
            // facilityjson:JSON.stringify(formData.facilityjson.map(e => {return {
            //     strLat:e.PointX || e.CenterLatitude,
            //     strLng:e.PointY || e.CenterLongitude
            // }})),
            facilityjson: "[]",
            labeljson:JSON.stringify(formData.labeljson),
            screeningJson:JSON.stringify(formData.screeningJson),
            strLat: formData.facilityjson.map(e => e.PointX || e.CenterLatitude)[0],
            strLng: formData.facilityjson.map(e => e.PointY || e.CenterLongitude)[0]
        }};

        getHotelList({},{...newData}).then((res:any) => {
            if(res.data){
                setHotelList(res.data)
                setCrt({...crt,...{latitude: Number(res.data.items[0].latitude),longitude: Number(res.data.items[0].longitude)}})
            }
        }).catch((err:any) => {

        })
    }

    const _delete = (str:any,index:any) => {
        let newFormData = formData;
        newFormData[str].splice(index,1);
        setFormData({...formData,...newFormData});
        props.changeData({...formData,...newFormData});
    }
 
    const _newMap = () => {
        console.log(props)
    }

    const _hotelList = () => {
        return <React.Fragment>
            {
                hotelList.items.map((item:any,index:number) => {
                    let serverList = [];
                    if (item.service.includes("91")) {
                        serverList.push("外宾");
                    }
                    item.service.filter((e:any) => e != "91").map((e:any) => {
                        switch (e) {
                            case "440":
                            serverList.push("普票");
                            break;
                            case "441":
                            serverList.push("3%专票");
                            break;
                            case "442":
                            serverList.push("6%专票");
                            break;
                            case "101":
                            serverList.push("自助选房");
                            break;
                            case "100":
                            serverList.push("积分兑");
                            break;
                            case "99":
                            serverList.push("早餐购");
                            break;
                        }
                    });
                    let LabelName = item.HotelLable?JSON.parse(item.HotelLable).filter((e:any) => e.LabelType == "店名后方")[0]?.LabelName:"";

                    return <div className="hotel-list ub" onMouseOver={() => {
                        setCrt({...crt,...{latitude: Number(item.latitude),longitude: Number(item.longitude)}});
                    }}>
                        <div className="list-left">
                            <img src={item.imageUrl || require("../../../assets/images/hotelList/noImage.png")} alt="" />
                        </div>
                        <div className="list-center">
                            <div className="hotel-name cut-2" onClick={() => {
                                    history.push(`/hotelDetail?hotelId=${item.id}&price=${item.price}`);
                                }}
                            ><span className={`${(formData.pageindex <= 1 && index < 5)?"bg":"width0"}`}>{(formData.pageindex <= 1 && index < 5)?(index + 1):""}</span>{item.name}</div>
                            <div className="hotel-address">{item.AddressLandmark}</div>
                            <div className="hotel-label ub">
                                {
                                    serverList.map((a) => {
                                        return <span>{a}</span>
                                    })
                                }
                            </div>
                            <div className="hotel-type">{LabelName}</div>
                        </div>
                        <div className="list-right">
                            <div className="list-score">
                                <div><span>{item.ScoreText}</span><span className="last">“{item.TopicLabel}”</span></div>
                                <div>
                                    <Rate disabled allowHalf value={Number(item.score)} style={{ fontSize: 20, color: "#FFD51A" }} />
                                </div>
                                <div>来自{item.TopicCount}条住客点评</div>
                            </div>
                            {
                                item.isFull == true && <div className="list-no">
                                    <img src={require("../../../assets/images/hotelList/full.png")} alt="" />
                                </div>
                            }
                            {
                                item.isFull == true && <div className="list-no">
                                    <img src={require("../../../assets/images/hotelList/noOpenDetail.png")} alt="" />
                                </div>
                            }
                            <div className="list-money">
                                <div><span>¥{item.price}</span>起</div>
                                <div onClick={() => {history.push(`/hotelDetail?hotelId=${item.id}&price=${item.price}`);}}>查看详情</div>
                            </div>
                        </div>
                    </div>
                })
            }
            <div className="hotel-pagination">
                <Pagination showSizeChanger={false} current={Number(hotelList.currentPage)} total={Number(hotelList.totalPage)*10} onChange={(page, pageSize) => {
                    setFormData({...formData,...{pageindex: page}})
                }}/>
            </div>
        </React.Fragment>
    }

    const  _DOM = () => {
        return <img src={require("../../../assets/images/hotelList/mapIcon.png")} alt="" />
    }

    const _map = () => {
        return <Map
                ref={mapRef}
                center={new BMapGL.Point(crt.longitude, crt.latitude)}
                zoom={12}
                tilt={40}
                enableScrollWheelZoom
            >
                <CustomOverlay map={props.map} position={new BMapGL.Point(crt.longitude, crt.latitude)}>
                    <_DOM />
                </CustomOverlay>
            </Map>
    }

    return (
        <React.Fragment>
            <div className="hotelList">
                <div className="hotel-content max-width">
                    <div className="hotel-screen ub">
                        <div className="hotel-total">已为您筛选出以下酒店</div>
                        <div className="screen-lists ub">
                            {
                                formData.brandjson.map((item,index) => {
                                    return <div className="screen-list cursor-pointer">{item.HotelBrandName}<img onClick={() => {_delete("brandjson",index)}} src={require("../../../assets/images/delete.png")} alt="" /></div>
                                })
                            }
                            {
                                formData.facilityjson.map((item,index) => {
                                    return <div className="screen-list cursor-pointer">{item.Name || item.XZName}<img onClick={() => {_delete("facilityjson",index)}} src={require("../../../assets/images/delete.png")} alt="" /></div>
                                })
                            }
                            {
                                formData.activityJson.map((item,index) => {
                                    return <div className="screen-list cursor-pointer">{item.activityName}<img onClick={() => {_delete("activityJson",index)}} src={require("../../../assets/images/delete.png")} alt="" /></div>
                                })
                            }
                            {
                                formData.labeljson.map((item,index) => {
                                    return <div className="screen-list cursor-pointer">{item.LabelName}<img onClick={() => {_delete("labeljson",index)}} src={require("../../../assets/images/delete.png")} alt="" /></div>
                                })
                            }
                        </div>
                        <div className="clear-screen cursor-pointer" onClick={() => {
                            setFormData({...formData,
                                activityJson: [],
                                facilityjson: [],
                                labeljson: [],
                                brandjson: [],
                                screeningJson:[]
                            })
                            props.changeData({...formData,
                                activityJson: [],
                                facilityjson: [],
                                labeljson: [],
                                brandjson: [],
                                screeningJson:[]
                            });
                        }}>清除</div>
                    </div>
                    <div className="hotel-lists ub">
                        <div className="lists-left">
                            <div className="lists-top ub">
                                <div className={`cursor-pointer ${hotelSortRule == 1?"actived":""}`} onClick={() => {
                                    setHotelSortRule(1);
                                }}>智能排序</div>
                                <div className={`cursor-pointer ub ub-center ${hotelSortRule == 2 || hotelSortRule == 3?"actived":""}`} onClick={() => {
                                    if (hotelSortRule == 2) {
                                        setHotelSortRule(3);
                                    } else {
                                        setHotelSortRule(2);
                                    }
                                }}>
                                    价格排序
                                    <div>
                                        <div>
                                            <CaretUpOutlined style={{fontSize:10,color:hotelSortRule==2?"#FF8F5C":"#d8d8d8"}} />
                                        </div>
                                        <div>
                                            <CaretDownOutlined style={{fontSize:10,color:hotelSortRule==3?"#FF8F5C":"#d8d8d8"}} />
                                        </div>
                                    </div>
                                </div>
                                <div className={`cursor-pointer ${hotelSortRule == 4?"actived":""}`} onClick={() => {
                                    setHotelSortRule(4);
                                }}>评分最高</div>
                                <div className={`cursor-pointer ${hotelSortRule == 5?"actived":""}`} onClick={() => {
                                    setHotelSortRule(5);
                                }}>离我最近</div>
                            </div>
                            {_hotelList()}
                        </div>
                        <div className="lists-right">
                            <div className="lists-map">
                                <div className="map-scale" onClick={() => {
                                    window.open(`https://998.com/HotelMap?cityId=${formData.cityId}&cityText=${formData.cityName}`)
                                }}><img src={require("../../../assets/images/hotelList/scale.png")} alt="" />酒店地图</div>
                                <div id="map">{_map()}</div>
                            </div>
                            <div className="lists-history">
                                <div className="title cursor-pointer">您浏览过的酒店<img src={require("../../../assets/images/clear.png")} alt="" onClick={() => {cookie.delete("hotelStory");setHotelStoryList([])}}/></div>
                                {
                                    JSON.parse(cookie.get("hotelStory") || "[]").map((item:any,index:number) => {
                                        return <div className="history-list ub" key={index} 
                                        onClick={() => {history.push(`/hotelDetail?hotelId=${item.hotelId}&price=${item.price}`);}}>
                                            <div className="history-left">
                                                <img src={item.img || require("../../../assets/images/hotelList/noImage.png")} alt="" />
                                            </div>
                                            <div className="history-right">
                                                <div className="history-name cut-2">{item.name}</div>
                                                    <div className="history-score ub ub-space-between ub-align-center">
                                                        <div className="history-star"><Rate disabled allowHalf value={Number(item.totalScore)} style={{ fontSize: 16, color: "#FFD51A" }} /></div>
                                                        <div className="history-money"><span>¥{item.price}</span>起</div>
                                                    </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </React.Fragment>
    )
}
export default forwardRef(HotelList);