import React, { useState, useEffect, useRef, useCallback, useImperativeHandle, forwardRef } from 'react';

import {
    Modal
} from 'antd';

import { Map, Marker, CustomOverlay } from 'react-bmapgl';

function MapModal(props: any, ref: any) {
    const [ isVisible, setIsVisible ] = useState(false);

    const [crt, setCrt] = useState({
        latitude: 39.928216,
        longitude: 116.402544
    })

    useEffect(() => {
        // setCrt()
    })

    //父组件调用子组件的方法 放在useImperativeHandle里面 可以写多个函数
    useImperativeHandle(ref, () => {
        return {
            _change(newCrt: any) {
                setCrt({...crt,...newCrt})
                setIsVisible(true);
            },
        };
    });

    const _DOM = () => {
        return <img src={require("../../../../assets/images/hotelList/mapIcon.png")} alt="" />
    }

    const _map = () => {
        return <Map
            center={new BMapGL.Point(crt.longitude, crt.latitude)}
            zoom={12}
            tilt={40}
            style={{height:600}}
            enableScrollWheelZoom
        >
            <CustomOverlay map={props.map} position={new BMapGL.Point(crt.longitude, crt.latitude)}>
                <_DOM />
            </CustomOverlay>
        </Map>
    }

    return (
        <React.Fragment>
            <Modal
                 title="查看地图"
                 visible={isVisible}
                 footer={null}
                 width={900}
                 onCancel={() => {setIsVisible(false)}}
                 maskClosable={false}
                 destroyOnClose
            >
                {_map()}
            </Modal>
        </React.Fragment>
    )
}
export default forwardRef(MapModal);