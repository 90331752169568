export default function (initRequest,baseUrl) {
    return {
        getBrandInfoSearch: initRequest.des("获取品牌列表").get("/api/HotelList/GetBrandInfoSearch"),
        getCityList: initRequest.des("获取城市列表(返回所有 一级城市)").get("/api/HotelList/GetCityList"),
        getKeySearch: initRequest.des("关键字搜索").get("/api/HotelList/KeySearch"),
        getHotPointAndXZQ: initRequest.des("根据城市ID获取位置/设施").get("/api/HotelList/GetHotPointAndXZQ"),
        getActivityList: initRequest.des("获取所有活动列表（促销）").get("/api/HotelList/GetActivityList"),
        getAllLabelType: initRequest.des("根据标签类型获取所有标签（其他筛选）").get("/api/HotelList/GetAllLabelType"),
        getNewHotelList: initRequest.des("新店特惠酒店列表").post("/api/HotelList/GetNewHotelList"),
        getHotelList: initRequest.des("获得酒店列表").post("/api/HotelList/GetHotelList"),

        getCityByLocation: initRequest.des("获取城市").get("/api/HotelList/GetCityByLocation"),
    }
}