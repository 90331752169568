import React,{Component} from 'react';
import { cookie } from "./approach/base"

import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Link,
  Switch
} from 'react-router-dom'
// import Login from './pages/Login';
import App from './App';

export default class route extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route path='/' component={RouteIndex}/>
        </Switch>
      </HashRouter>
    )
  }
}
class RouteIndex extends Component {
  constructor(props){
    super(props);
  }
  componentWillMount(){
    this.judgeRoute();
  }
  componentWillUpdate=()=>{
    this.judgeRoute();
  };
  componentDidUpdate(){
    document.getElementsByClassName('router-content')[0].scrollTop = 0;
  }
  judgeRoute=()=>{
    // const {pathname} = this.props.location;
    // if(cookie.get('access_token')){
    //   if(pathname === '/' || pathname === '/login'){
    //     this.props.history.replace('/home');
    //   }
    // }else{
    //   if(pathname !== '/login') {
    //     this.props.history.replace('/login');
    //   }
    // }
  };
  render(){
    return <Switch style={{width:'100%',height:'100%'}}>
      <Route path="/login" component={App}/>
      <Route path="/" component={App}/>
    </Switch>
  }
}
