import React, { useState, useEffect, useRef, useCallback } from 'react';
import './index.scss';

import {
    Card, Col, Row
} from 'antd';

import { useHistory } from 'react-router-dom'

function Footer(props: any, ref: any) {
 
    const history = useHistory();

    return (
        <div className="footer">
            <div className="footer-top">
                <div className="footer-content max-width ub ub-space-between">
                    <div>
                        <img src={require("../../assets/images/logoslogan.png")} alt="" />
                        <div>
                            <a href="https://weibo.com/greentreeinn" target="_blank" rel="noopener noreferrer"><img src={require("../../assets/images/weibo.png")} title="格林微博" /></a>
                            <a href="https://weibo.com/xushuguang?refer_flag=1005050003_" target="_blank" rel="noopener noreferrer"><img src={require("../../assets/images/weibo.png")} title="曙光微博" /></a>
                            <a href="https://998.com/Help/detaile?typeId=a5e11fd4-deea-4cd0-8cbe-aa64aaa06511&infoId=1706d4ae-2da6-4224-b826-362795d89d1e" target="_blank" rel="noopener noreferrer"><img src={require("../../assets/images/tousu.png")} title="投诉建议" /></a>
                        </div>
                    </div>
                    <div className="ub">
                        <div>
                            <div>酒店预订</div>
                            <a href="https://998.com/HotelList" target="_blank" rel="noopener noreferrer">列表预订</a>
                            <a href="https://998.com/HotelMap" target="_blank" rel="noopener noreferrer">地图预订</a>
                            <a href="https://www.greentreeinn.com/" target="_blank" rel="noopener noreferrer">格林美国</a>
                        </div>
                        <div>
                            <div>合作加盟</div>
                            <a href="https://998.com/Join/JoiningGreen" target="_blank" rel="noopener noreferrer">加盟格林</a>
                            <a href="https://998.com/Information/cooperate" target="_blank" rel="noopener noreferrer">商务合作</a>
                            <a href="https://998.com/NewsCenter/DetailNew?id=35a24f2e-8b21-48ea-89d1-66381698c80c" target="_blank" rel="noopener noreferrer">招标信息</a>
                            <a href="https://998.com/NewsCenter/SupplierInfo?id=35a24f2e-8b21-48ea-89d1-66381698c80c" target="_blank" rel="noopener noreferrer">供应商名录</a>
                        </div>
                        <div>
                            <div>关于格林</div>
                            <a href="https://998.com/Information/TouchUs" target="_blank" rel="noopener noreferrer">联系我们</a>
                            <a href="https://998.com/Information/defaultu" target="_blank" rel="noopener noreferrer">关于格林</a>
                            <a href="https://ir.998.com/" target="_blank" rel="noopener noreferrer">投资者关系</a>
                            <a href="https://998.zhiye.com/" target="_blank" rel="noopener noreferrer">人才</a>
                            <a href="https://998.com/AppdownloadPage/index.html" target="_blank" rel="noopener noreferrer">格林APP</a>
                        </div>
                    </div>
                    <div>
                        <img src={require("../../assets/images/servicePhone.png")} />
                    </div>
                </div>
                <div className="footer-record">
                    <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010502004130" target="_blank" rel="noopener noreferrer">沪公网安备31010502004130号 </a>
                    <a href="http://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer"> 格林酒店集团版权所有 沪ICP备14035076号 | 投资有风险,选择需谨慎</a>
                </div>
            </div>
            <div className="footer-bottom" style={{display:"none"}}>
                {/* <a href="http://cn.argylehotels.com/" target="_blank" rel="noopener noreferrer">雅阁集团</a> */}
                <a href="http://www.unistar-group.cn/index_bak.html" target="_blank" rel="noopener noreferrer">都市集团</a>
                <a href="https://jyhm.com.cn" target="_blank" rel="noopener noreferrer">家有好面</a>
                <a href="http://www.dnsj1996.cn/" target="_blank" rel="noopener noreferrer">大娘水饺</a>
                <a href="http://www.bellagiocafe.com.cn/" target="_blank" rel="noopener noreferrer">鹿港小镇</a>
                <a href="http://www.519.com/" target="_blank" rel="noopener noreferrer">进口葡萄酒</a>
                <a href="http://www.chinagingkoedu.com/" target="_blank" rel="noopener noreferrer">银杏教育</a>
                <a href="https://www.booking.com" target="_blank" rel="noopener noreferrer">Booking </a>
                <a href="https://www.expedia.com" target="_blank" rel="noopener noreferrer">Expedia</a>
                <a href="https://www.agoda.com" target="_blank" rel="noopener noreferrer">Agoda</a>
                <a href="https://www.ctrip.com" target="_blank" rel="noopener noreferrer">携程</a>
                <a href="https://hotel.meituan.com" target="_blank" rel="noopener noreferrer">美团</a>
                <a href="https://www.fliggy.com" target="_blank" rel="noopener noreferrer">飞猪</a>
                <a href="https://www.qunar.com" target="_blank" rel="noopener noreferrer"> 去哪儿</a>
                <a href="https://www.ly.com" target="_blank" rel="noopener noreferrer">同程酒店</a>
                <a href="http://www.mafengwo.cn" target="_blank" rel="noopener noreferrer">马蜂窝旅游攻略</a>
                <a href="https://www.xiamenair.com" target="_blank" rel="noopener noreferrer">厦门航空</a>
                <a href="http://www.qmango.com" target="_blank" rel="noopener noreferrer">青芒果网</a>
                <a href="http://www.aoyou.com" target="_blank" rel="noopener noreferrer">遨游网 </a>
                <a href="https://www.damai.cn" target="_blank" rel="noopener noreferrer">大麦网</a>
                <a href="http://www.auyou.cn" target="_blank" rel="noopener noreferrer">澳游搜</a>
                <a href="http://www.quanmama.com" target="_blank" rel="noopener noreferrer">券妈妈</a>
                <a href="https://www.12306.cn" target="_blank" rel="noopener noreferrer">火车票网</a>
            </div>
        </div>
    )
}
export default Footer;