"use strict";
/**
 * @description 函数防抖 防抖，就是指触发事件后在 n 秒内函数只能执行一次，如果在 n 秒内又触发了事件，则会重新计算函数执行时间。
 * @param handler  参数 函数
 * @param wait     参数 等待的时间
 * @returns 返回新函数
 */
Object.defineProperty(exports, "__esModule", { value: true });
function debounce(func, wait) {
    var timeout;
    return function () {
        var context = this;
        var args = arguments;
        if (timeout)
            clearTimeout(timeout);
        var callNow = !timeout;
        timeout = setTimeout(function () {
            timeout = null;
        }, wait);
        if (callNow)
            func.apply(context, args);
    };
}
exports.default = debounce;
