"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * @description 获取字节数
 * @param str  接收字符串
 * @returns 返回字节数
 */
function getLength(str) {
    if (str) {
        var realLength = 0;
        for (var i = 0; i < str.length; i++) {
            var charCode = str.charCodeAt(i);
            if (charCode >= 0 && charCode <= 128)
                realLength += 1;
            else
                realLength += 2;
        }
        return realLength;
    }
    else {
        return 0;
    }
}
;
exports.default = getLength;
