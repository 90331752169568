import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';

import {Provider} from 'react-redux';

import Store from './store/redux';
import store from './store/store';
import actionCreator from './store/actionCreator';

import Router from './router';
import * as serviceWorker from './serviceWorker';

import {cookie} from "./approach/base";

import componentConstruct from "./apis";

const env = process.env;
const service = new componentConstruct({ env });

service.initRequest.setEnv(null, { "Authorization":"Bearer "+ cookie.get("access_token")});

React.$axios = service;

React.$Store = Store;
React.$store = store;
React.$actionCreator = actionCreator;

window.$Store = Store;
window.$axios = service;

ReactDOM.render(
  <React.Profiler id="reactRouter" onRender={() => {}}>
    <Router />
  </React.Profiler>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
