import React, { useState, useEffect, useRef, useCallback } from 'react';
import './index.scss';

import {
    Card, Col, Row
} from 'antd';

import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/footer';

import Search from '../../components/search';
import HotelDetail from './hotelDetail';

function HotelDetailPage(props: any, ref: any) {

    const [ formData, setFormData ] = useState({});

    const searchRef:any = useRef();
    const hotelListRef:any = useRef();

    useEffect(() => {
        _getFilter();
    }, [])
 
    const _getFilter = () => {
        
    }

    return (
        <React.Fragment>
            <Header />
            <Search />
            <HotelDetail />
            <Footer />
        </React.Fragment>
    )
}
export default HotelDetailPage;