import React, { useState, useEffect, useRef, useCallback, useImperativeHandle, forwardRef } from 'react';

import {
    Rate
} from 'antd';

function Merchant(props: any, ref: any) {

    const [ merchantType, setMerchantType ] = useState(3);

    const [ hotelPeriphery, setHotelPeriphery ] = useState([]);

    const merchantRef:any = useRef();

    const { getHotelPeriphery } = window.$axios.hotelDetail;

    useEffect(() => {
        _getHotelPeriphery();
    }, [merchantType])

    //父组件调用子组件的方法 放在useImperativeHandle里面 可以写多个函数
    useImperativeHandle(ref, () => {
        return {
            _scoll() {
                return merchantRef.current.offsetTop;
            },
        };
    });

    const _getHotelPeriphery = () => {
        getHotelPeriphery({
            longitude: props.propsData.longitude,
            latitude: props.propsData.latitude,
            hotelCode: props.propsData.hotelCode,
            pagesize: 10,
            pageindex: 1,
            MerchantType: merchantType //1交通设施 2景点娱乐 3餐饮 4城市指南
        }).then((res:any) => {
            setHotelPeriphery(res.data.items)
        }).catch((err:any) => {

        })
    }

    const _merchant = () => {
        return <div className="merchant" ref={merchantRef}>
            <div className="title">周边商户</div>
            <div className="merchant-tabs ub">
                <div className={`${merchantType == 3? "actived":""}`} onClick={() => {setMerchantType(3)}}>餐饮</div>
                <div className={`${merchantType == 2? "actived":""}`} onClick={() => {setMerchantType(2)}}>景点娱乐</div>
                <div className={`${merchantType == 1? "actived":""}`} onClick={() => {setMerchantType(1)}}>交通设施</div>
            </div>
            {
                hotelPeriphery.map((item:any,index:number) => {
                    return <div className="merchant-list ub" key={index}>
                        <div className="merchant-left">
                            <img src={item.imageUrl || require("../../../../assets/images/hotelDetail/periphery.png")} alt="" />
                        </div>
                        <div className="merchant-right">
                            <div className="merchant-name cut-2">{item.MerchantName}</div>
                            {/* <div className="merchant-intro cut-2"></div> */}
                        </div>
                    </div>
                })
            }
            
        </div>
    }

    return (
        <React.Fragment>
            {_merchant()}
        </React.Fragment>
    )
}
export default forwardRef(Merchant);