"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.href.split("?")[1]?.match(reg);
    if (r != null)
        return decodeURIComponent(r[2]);
    return null;
}
exports.default = default_1;
;
