import React, { useState, useEffect, useRef, useCallback, useImperativeHandle, forwardRef } from 'react';
import './index.scss';

import {
    UpOutlined,
    DownOutlined
} from '@ant-design/icons';

import {
    Carousel,Modal
} from 'antd';

function ImageList(props: any, ref: any) {

    const [ isVisible, setIsVisible ] = useState(false);

    const [ imageList, setImageList ] = useState<{
        list1: any[],
        list2: any[],
        list3: any[],
        list4: any[]
    }>({
        list1: [],
        list2: [],
        list3: [],
        list4: []
    })

    const [ activeImageList, setActiveImageList ] = useState<any[]>([]);
    const [ activeImg, setActiveImg ] = useState("");
    const [ activeBigImg, setActiveBigImg ] = useState("");
    const [ activeType, setActiveType ] = useState(0);

    const carouselRef: any = useRef();

    useEffect(() => {
        
    },[])

    //父组件调用子组件的方法 放在useImperativeHandle里面 可以写多个函数
    useImperativeHandle(ref, () => {
        return {
            _change(data: any) {
                setIsVisible(true);
                setActiveType(0);
                setImageList(data);
                setActiveImageList(_changeActiveImageList([...data.list1,...data.list2,...data.list3,...data.list4]));
                setActiveImg([...data.list1,...data.list2,...data.list3,...data.list4][0]?.imageList[0]?.img);
                setActiveBigImg([...data.list1,...data.list2,...data.list3,...data.list4][0]?.imageList[0]?.bigimg);
            },
        };
    });

    const _changeActiveImageList = (list:any) => {
        let newAllArr:any = [];
        let newCurrentArr:any = [];
        list.map((item:any,index:number) => {
            newCurrentArr.push(item);
            if((index != 0 && (index+1)%5 == 0) || index == list.length - 1){
                newAllArr.push(newCurrentArr);
                newCurrentArr = []
            }
        })
        return newAllArr;
    }


    const _dialogue = () => {
        return <div className="dialogue ub">
            <div className="upOutlined" onClick={() => { carouselRef.current.prev() }}><UpOutlined  style={{ fontSize: 18, color: "#FFF" }} /></div>
            <div className="carousel">
                <Carousel
                    // autoplay
                    dotPosition="left"
                    dots={false}
                    ref={carouselRef}
                >
                    {
                        activeImageList.map((item:any,index:number) => {
                            return <div key={index}>
                                <div className="dialogue-img">
                                    {
                                        item.map((img:any,imgIndex:number) => {
                                            return <img 
                                                className={`${activeImg == img.imageList[0]?.img?"actived":""}`} 
                                                key={imgIndex} 
                                                src={img.imageList[0]?.img || require("../../../../assets/images/hotelDetail/roomType.png")} 
                                                alt="" 
                                                onClick={() => {
                                                    setActiveImg(img.imageList[0]?.img);
                                                    setActiveBigImg(img.imageList[0]?.img);
                                                }}
                                            />
                                        })
                                    }
                                </div>
                            </div>
                        })
                    }
                </Carousel>
            </div>
            <div className="downOutlined" onClick={() => { carouselRef.current.next() }}><DownOutlined style={{ fontSize: 18, color: "#FFF" }} /></div>
        </div>
    }

    const _render = () => {
        return <div className="image-list ub">
            <div className="big-img"><img src={activeBigImg || require("../../../../assets/images/hotelDetail/bg.png")} alt="" /></div>
            <div className="img">
                {_dialogue()}
            </div>
            <div className="img-type">
                <div className={`${activeType == 0?"actived":""}`} onClick={() => {
                        setActiveType(0);
                        setActiveImageList(_changeActiveImageList([...imageList.list1,...imageList.list2,...imageList.list3,...imageList.list4]));
                    }}
                >所有({imageList.list1.length + imageList.list2.length + imageList.list3.length + imageList.list4.length})</div>
                <div className={`${activeType == 1?"actived":""}`} onClick={() => {
                        setActiveType(1);
                        setActiveImageList(_changeActiveImageList([...imageList.list1]));
                    }}
                >外观与周边({imageList.list1.length})</div>
                <div className={`${activeType == 2?"actived":""}`} onClick={() => {
                        setActiveType(2);
                        setActiveImageList(_changeActiveImageList([...imageList.list2]));
                    }}
                >公共设施({imageList.list2.length})</div>
                <div className={`${activeType == 3?"actived":""}`} onClick={() => {
                        setActiveType(3);
                        setActiveImageList(_changeActiveImageList([...imageList.list3]));
                    }}
                >客房({imageList.list3.length})</div>
                <div className={`${activeType == 4?"actived":""}`} onClick={() => {
                        setActiveType(4);
                        setActiveImageList(_changeActiveImageList([...imageList.list4]));
                    }}
                >早餐({imageList.list4.length})</div>
            </div>
        </div>
    }

    return (
        <React.Fragment>
            <Modal
                 title="查看图片"
                 visible={isVisible}
                 footer={null}
                 width={960}
                 onCancel={() => {setIsVisible(false)}}
                 maskClosable={false}
                 destroyOnClose
            >
                {_render()}
            </Modal>
        </React.Fragment>
    )
}
export default forwardRef(ImageList);