import Service from './server/index';
import request from './request/request';
import hotelList from './request/hotelList';
import hotelDetail from './request/hotelDetail';

const baseUrl = process.env.REACT_APP_IMAGE_URL;

export default class {
  constructor(option) {
    const service = new Service(option);
    service.initRequest = service.initRequest;
    service.request = request(service.initRequest,baseUrl);
    service.hotelList = hotelList(service.initRequest,baseUrl);
    service.hotelDetail = hotelDetail(service.initRequest,baseUrl);
    return service;
  }
}
