import React, { useState, useEffect, useRef, useCallback, useImperativeHandle, forwardRef } from 'react';

import {
    Modal
} from 'antd';

function AppDownloadModal(props: any, ref: any) {
    const [ isVisible, setIsVisible ] = useState(false);
    useEffect(() => {

    })

    //父组件调用子组件的方法 放在useImperativeHandle里面 可以写多个函数
    useImperativeHandle(ref, () => {
        return {
            _change() {
                setIsVisible(true);
            },
        };
    });

    const _appDownload = () => {
        return <div style={{textAlign: "center"}}><img src={require("../../../../assets/images/qrcode.png")} alt="" /></div>
    }

    return (
        <React.Fragment>
            <Modal
                 title=""
                 visible={isVisible}
                 footer={null}
                 width={770}
                 onCancel={() => {setIsVisible(false)}}
                 maskClosable={false}
                 destroyOnClose
            >
                {_appDownload()}
            </Modal>
        </React.Fragment>
    )
}
export default forwardRef(AppDownloadModal);