import React, { useState, useEffect, useRef, useCallback, useImperativeHandle, forwardRef } from 'react';
import './index.scss';

import {
    LeftOutlined,
    RightOutlined
} from '@ant-design/icons';

import {
    Breadcrumb, DatePicker, message
} from 'antd';

import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/date-picker/locale/zh_CN';

import { useHistory } from 'react-router-dom'
import { Map, Marker, CustomOverlay } from 'react-bmapgl';

import { crypto,getQueryString,cookie } from 'approach/base';

import Comment from './module/comment';
import Question from './module/question';
import History from './module/history';
import Merchant from './module/merchant';
import MapModal from './module/map';
import ImageList from './module/imageList';
import RoomDetail from './module/roomDetail';
import AppDownload from './module/appDownload';


function HotelDetail(props: any, ref: any) {
    
    const [userId, setUserId] = useState(JSON.parse(decodeURIComponent(`${cookie.get("userInfo")}`))?JSON.parse(decodeURIComponent(`${cookie.get("userInfo")}`)).Card_no:"");
    const [hotelId, setHotelId] = useState(getQueryString("hotelId") || "");
    const [hotelPrice, setHotelPrice] = useState(getQueryString("price") || "");

    const [crt, setCrt] = useState({
        latitude: 39.928216,
        longitude: 116.402544
    })

    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");

    const [hotelDetail, setHotelDetail] = useState({
        rooms: [],
        activityrooms: []
    });

    const [hotelStaticData, setHotelStaticData] = useState({
        AddressLandmark: "",
        Decorationdate: "",
        HotelBrand: "",
        HotelFacilities: {},
        HotelLabel: "",
        HourRoomEndTime: "",
        HourRoomStartTime: "",
        NewHotelFacilities: "[]",
        ScoreText: "",
        SeeHouseStart: "",
        TopicLabel: "[]",
        Url360: "",
        VideoUrl: "",
        address: "",
        backPhone: "",
        commentsCount: "",
        description: "",
        disclaimer: "",
        featuredLableList: null,
        imageInfo: [],
        isEarlierBusiness: "",
        latitude: "",
        logoImage: [{bigimg:""}],
        longitude: "",
        minReserveHours: null,
        name: "",
        openDate: "",
        phone: "",
        promotions: [],
        service: [],
        specialNoteList: "[]",
        supportLeisureRoom: "",
        supportMeetRoom: "",
        totalScore: "",
        isCollectHotel:"0"
    });
    const [ hotelPeriphery, setHotelPeriphery ] = useState([]);

    const [ imageList, setImageList ] = useState<{
        list1: any[],
        list2: any[],
        list3: any[],
        list4: any[]
    }>({
        list1: [],
        list2: [],
        list3: [],
        list4: []
    })

    const roomListRef:any = useRef();
    const hotelDetailRef:any = useRef();
    const commentRef:any = useRef();
    const merchantRef:any = useRef();
    
    const mapRef:any = useRef();
    const imageListRef:any = useRef();
    const roomDetailRef:any = useRef();
    const appDownloadRef:any = useRef();

    const { getHotelDetail, getHotelStaticData, getHotelPeriphery, postHotelOrder } = window.$axios.hotelDetail;

    useEffect(() => {
        setStartTime(_getTime(new Date().getTime()));
        setEndTime(_getTime(new Date(new Date().getTime() + 24 * 60 * 60 * 1000).getTime()));
        _getHotelStaticDetail(_getTime(new Date().getTime()));
        _getHotelDetail(_getTime(new Date().getTime()));
    }, [])

    //父组件调用子组件的方法 放在useImperativeHandle里面 可以写多个函数
    useImperativeHandle(ref, () => {
        return {
            _change(data: any) {

            },
        };
    });

    const _getHotelStaticDetail = (startDate:any) => {
        getHotelStaticData({
            hotelId: hotelId,
            userId: userId,
            startDate: startDate,
            activityId: "",
            flag: "1",
            isMorningRoom: "",
            sourceCode: ""
        }).then((res: any) => {
            setHotelStaticData(res.data);
            setCrt({
                latitude: res.data.latitude,
                longitude: res.data.longitude
            })
            _getHotelPeriphery(res.data.latitude,res.data.longitude)

            let newImageList = imageList;
            res.data.imageInfo.map((item:any) => {
                if(item.imageType == 1){
                    newImageList.list1.push(item);
                }   
                if(item.imageType == 2){
                    newImageList.list2.push(item);
                }
                if(item.imageType == 3){
                    newImageList.list3.push(item);
                }
                if(item.imageType == 4){
                    newImageList.list4.push(item);
                }
            })
            setImageList({...imageList,...newImageList});
            _setCookie(res.data);
        }).catch((err: any) => {
            console.log(err)
        })
        
    }

    const _getHotelDetail = (startDate:any) => {
        getHotelDetail({
            hotelId: hotelId,
            userId: userId,
            startDate: startDate,
            activityId: "",
            flag: "1",
            isMorningRoom: "",
            sourceCode: ""
        }).then((res: any) => {
            if(res.data){
                setHotelDetail(res.data);
            }
        }).catch((err: any) => {
            console.log(err)
        })
    }

    const _setCookie = (data:any) => {
        let newCookie = JSON.parse(cookie.get("hotelStory") || "[]");
        if (newCookie.map((e:any) => e.hotelId).includes(hotelId)) {
            return
        }
        newCookie.unshift({
            hotelId:hotelId,
            name:data.name,
            price:hotelPrice,
            totalScore:data.totalScore,
            img:data.logoImage[0].img
        })
        cookie.set("hotelStory",JSON.stringify(newCookie.slice(0,5)),30)
    }

    const _getHotelPeriphery = (longitude:any,latitude:any) => {
        getHotelPeriphery({
            longitude: longitude,
            latitude: latitude,
            hotelCode: hotelId,
            pagesize: 5,
            pageindex: 1,
            MerchantType: 1 //1交通设施 2景点娱乐 3餐饮 4城市指南
        }).then((res:any) => {
            setHotelPeriphery(res.data.items)
        }).catch((err:any) => {
            console.log(err)
        })
    }

    const _collection = () => {
        if (hotelStaticData.isCollectHotel == "0") {
            setHotelStaticData({...hotelStaticData,isCollectHotel:"1"})
        }else{
            message.error("已收藏!")
        }
        
    }

    const _postHotelOrder = (activityCode:any,roomType:any,isSP:any) => {
        // let origin = window.location.origin == "https://test-hotel.998.com:15443"?"https://test-www.998.com:15443":"https://998.com/Account";
        // let url = origin + `/HotelOrder?hotelCode=${hotelId}&activityCode=${activityCode}&roomType=${roomType}&startDate=${startTime}&endDate=${endTime}&isSP=${isSP}&totaldays=${(new Date(endTime).getTime() - new Date(startTime).getTime()) / (24 * 60 * 60 * 1000)}`
        // window.open(url)
        appDownloadRef.current._change(crt)
    }

    const _getTime = (time: number) => {
        let newTime = new Date(time);
        let month = (newTime.getMonth() + 1) > 9 ? (newTime.getMonth() + 1) : "0" + (newTime.getMonth() + 1);
        let day = newTime.getDate() > 9 ? newTime.getDate() : "0" + newTime.getDate();
        return newTime.getFullYear() + "-" + month + "-" + day;
    }

    const _DOM = () => {
        return <img src={require("../../../assets/images/hotelList/mapIcon.png")} alt="" />
    }

    const _map = () => {
        return <Map
            center={new BMapGL.Point(crt.longitude, crt.latitude)}
            zoom={12}
            tilt={40}
            enableScrollWheelZoom
        >
            <CustomOverlay map={props.map} position={new BMapGL.Point(crt.longitude, crt.latitude)}>
                <_DOM />
            </CustomOverlay>
        </Map>
    }

    const _detailHeader = () => {
        return <div className="detail-header">
            <div className="breadcrumb">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item href="https://998.com/">首页</Breadcrumb.Item>
                    <Breadcrumb.Item href="#/hotelList">酒店预订</Breadcrumb.Item>
                    <Breadcrumb.Item>{hotelStaticData.name}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="detail-image">
                <div className="hotel-info ub ub-space-between">
                    <div className="left">
                        <div className="hotel-name">{hotelStaticData.name}</div>
                        <div className="hotel-address">地址： {hotelStaticData.address}</div>
                        <div className="ub">
                            <div className="hotel-type">{hotelStaticData.HotelLabel}</div>
                            <div className="hotel-year">{hotelStaticData.Decorationdate}</div>
                            <div className="division"></div>
                            <div className="hotel-label ub">
                                {
                                    hotelStaticData.service.map((item,index) => {
                                        switch (item) {
                                            case "91":
                                                return <span key={index}>外宾</span>
                                                break;
                                            case "440":
                                                return <span key={index}>普票</span>
                                                break;
                                            case "441":
                                                return <span key={index}>3%专票</span>
                                                break;
                                            case "442":
                                                return <span key={index}>6%专票</span>
                                                break;
                                            case "101":
                                                return <span key={index}>自助选房</span>
                                                break;
                                            case "100":
                                                return <span key={index}>积分兑</span>
                                                break;
                                            case "99":
                                                return <span key={index}>早餐购</span>
                                                break;
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="right ub">
                        <div></div>
                        <div>
                            {
                                hotelPrice != "undefined" && hotelPrice != "" && <div className="price"><span>¥{hotelPrice}</span>起</div>
                            }
                            <div className="toRoom" onClick={() => {
                                document.getElementsByClassName('router-content')[0].scrollTop = roomListRef.current.offsetTop;
                            }}>查看房型</div>
                        </div>
                    </div>
                </div>
                <div className="hotel-image ub">
                    <div onClick={() => {imageListRef.current._change(imageList)}}>
                        {/* <img className="collection" onClick={_collection} src={require(`../../../assets/images/hotelDetail/${hotelStaticData.isCollectHotel == "0"?"nocollection":"collection"}.png`)} alt="" /> */}
                        <img className="image1" src={hotelStaticData.logoImage != null?hotelStaticData.logoImage[0].bigimg:require("../../../assets/images/hotelDetail/bg-big.png")} alt="" />
                        <div className="totalImage ub ub-align-center">
                            <img src={require("../../../assets/images/hotelDetail/lookImage.png")} alt="" />
                            查看{hotelStaticData.imageInfo?.length}张照片
                        </div>
                    </div>
                    <div onClick={() => {imageListRef.current._change(imageList)}}>
                        <img className="image2" src={imageList.list2[0]?.imageList[0].img || require("../../../assets/images/hotelDetail/bg.png")} alt="" />
                        <img className="image2" src={imageList.list2[1]?.imageList[0].img || require("../../../assets/images/hotelDetail/bg.png")} alt="" />
                    </div>
                    <div onClick={() => {imageListRef.current._change(imageList)}}>
                        <img className="image2" src={imageList.list3[0]?.imageList[0].img || require("../../../assets/images/hotelDetail/bg.png")} alt="" />
                        <img className="image2" src={imageList.list4[0]?.imageList[0].img || require("../../../assets/images/hotelDetail/bg.png")} alt="" />
                    </div>
                    <div className="hotel-map">
                        <div className="look-map" onClick={() => {mapRef.current._change(crt)}}>查看地图</div>
                        {_map()}
                    </div>
                    <div>
                        <div><span>{hotelStaticData.totalScore}</span>/5分</div>
                        <div>来自{hotelStaticData.commentsCount}条住客点评</div>
                        <div>
                            {
                                hotelStaticData.TopicLabel && <div>
                                    {
                                        JSON.parse(hotelStaticData.TopicLabel).map((item: any,index:number) => {
                                            return <div key={index}>{item}</div>
                                        })
                                    }
                                </div>
                            }
                            
                        </div>
                        <div>
                            <img src={require("../../../assets/images/hotelDetail/phone.png")} alt="" title={hotelStaticData.phone} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    const _detailTabs = () => {
        return <div className="detail-tabs ub">
            <div className="actived" onClick={() => {
                document.getElementsByClassName('router-content')[0].scrollTop = roomListRef.current.offsetTop;
            }}>房型预订</div>
            <div onClick={() => {
                document.getElementsByClassName('router-content')[0].scrollTop = hotelDetailRef.current.offsetTop;
            }}>酒店信息</div>
            <div onClick={() => {
                document.getElementsByClassName('router-content')[0].scrollTop = commentRef.current._scoll();
            }}>酒店点评</div>
            <div onClick={() => {
                document.getElementsByClassName('router-content')[0].scrollTop = merchantRef.current._scoll();
            }}>周边商户</div>
        </div>
    }

    const _detailRoom = () => {
        return <div className="detail-room" ref={roomListRef}>
            <div className="room-search ub ub-align-center">
                <div>入住</div>
                <div onClick={(e) => {e.nativeEvent.stopImmediatePropagation();}}>
                    <DatePicker
                        className="datePicker"
                        locale={locale}
                        value={moment(startTime)}
                        disabledDate={(current: any) => {
                            return current < moment().subtract(1, 'days');
                        }}
                        onChange={(date, dateString) => {
                            setStartTime(dateString);
                            if (moment(dateString).valueOf() >= moment(endTime).valueOf()) {
                                setEndTime(_getTime(moment(dateString).valueOf() + 24 * 60 * 60 * 1000));
                            }
                        }}
                        format="YYYY-MM-DD"
                        bordered={false}
                    />
                </div>
                <div>-{(new Date(endTime).getTime() - new Date(startTime).getTime()) / (24 * 60 * 60 * 1000)}晚 -</div>
                <div>离店</div>
                <div onClick={(e) => {e.nativeEvent.stopImmediatePropagation();}}>
                    <DatePicker
                        className="datePicker"
                        locale={locale}
                        value={moment(endTime)}
                        disabledDate={(current: any) => {
                            return current.valueOf() <= moment(startTime).valueOf();
                        }}
                        onChange={(date, dateString) => {
                            setEndTime(dateString);
                        }}
                        format="YYYY-MM-DD"
                        bordered={false}
                    />
                </div>
                <div onClick={() => {_getHotelDetail(startTime)}}>搜索</div>
            </div>
            <div className="room-type">
                <div className="room-type-header ub ub-align-center">
                    <div>房型</div>
                    <div>早餐</div>
                    <div>门市价 / 会员价</div>
                    <div>房态</div>
                    <div>优惠</div>
                    <div></div>
                </div>
                <div className="room-type-lists">
                    {
                        hotelDetail.rooms.map((item: any,index: number) => {
                            return <div className="room-type-list" key={index}>
                                <div className="list-header ub ub-align-center">
                                    <div className="ub">
                                        <div><img src={crypto.desDecode(item.roomTypePhotoUrl) || require("../../../assets/images/hotelDetail/roomType.png")} alt="" /></div>
                                        <div>
                                            <div>{crypto.desDecode(item.typeName)}</div>
                                            <div>{crypto.desDecode(item.typeDescription)}</div>
                                            <div  onClick={() => {roomDetailRef.current._change(hotelId,item)}}>房型详情</div>
                                        </div>
                                    </div>
                                    <div className={`${crypto.desDecode(item.presentBreakfastTimes) == "不赠早" ? "no" : ""}`}>{crypto.desDecode(item.presentBreakfastTimes)}</div>
                                    <div><span className="no">¥{crypto.desDecode(item.allPrice.retailPrice)} /</span><span>¥{crypto.desDecode(item.price)}</span></div>
                                    <div>{crypto.desDecode(item.isFullState) == "1" ? "满房" : "有空房"}</div>
                                    <div className="ub">
                                        {
                                            Array.from(new Set(item.discountInfoList.map((e:any) => crypto.desDecode(e)))).includes("30") && <div>¥30</div>
                                            
                                        }
                                        {
                                            Array.from(new Set(item.discountInfoList.map((e:any) => crypto.desDecode(e)))).includes("20") && <div>¥20</div>
                                            
                                        }
                                        {
                                            Array.from(new Set(item.discountInfoList.map((e:any) => crypto.desDecode(e)))).includes("10") && <div>¥10</div>
                                            // Array.from(new Set(item.discountInfoList.map((e:any) => crypto.desDecode(e)))).map((info: any,infoIndex:number) => {
                                            //     return <div key={infoIndex}>{parseFloat(info) == NaN?info:("¥"+info)}</div>
                                            // })
                                        }
                                    </div>
                                    <div>
                                        {
                                            crypto.desDecode(item.isFullState) == "2" && <div className="nomal payMoneyFull">
                                                <div>当日暂停</div>
                                                <div>线上预订</div>
                                            </div>
                                        }
                                        {
                                            crypto.desDecode(item.isFullState) == "1" && <div className="nomal full">满房</div>
                                        }
                                        {
                                            (crypto.desDecode(item.isFullState) != "1" && crypto.desDecode(item.isFullState) != "2") && <div className="nomal" onClick={() => {_postHotelOrder("",crypto.desDecode(item.id),false)}}>预订</div>
                                        }
                                        {/* <div className="nomal">预订</div>
                                        <div className="nomal full">满房</div>
                                        <div className="nomal payMoney">
                                            <div>预订</div>
                                            <div>需预付</div>
                                        </div>
                                        <div className="nomal payMoneyFull">
                                            <div>预订</div>
                                            <div>需提前一天</div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="list-contents">
                                    {
                                        hotelDetail.activityrooms.map((activityrooms: any,activityIndex: number) => {
                                            if (crypto.desDecode(activityrooms.id) != crypto.desDecode(item.id)) {
                                                return
                                            }
                                            return <div className="list-content ub ub-align-center" key={activityIndex}>
                                                <div className="ub ub-align-center">
                                                    <img className="mark" src={require("../../../assets/images/hotelDetail/mark.png")} alt="" />
                                                    <div>{crypto.desDecode(activityrooms.typeName)}</div>
                                                    {/* <img className="explain" src={require("../../../assets/images/hotelDetail/explain.png")} alt="" title="酒店活动介绍" /> */}
                                                </div>
                                                <div className={`${crypto.desDecode(item.presentBreakfastTimes) == "不赠早" ? "no" : ""}`}>{crypto.desDecode(activityrooms.presentBreakfastTimes)}</div>
                                                <div><span className="no"></span><span>¥{crypto.desDecode(activityrooms.price)}</span></div>
                                                <div>
                                                    {
                                                        crypto.desDecode(activityrooms.isFullState) == "1" ? "满房" :
                                                            crypto.desDecode(activityrooms.availableRms) < 5 ?
                                                                ("剩" + crypto.desDecode(activityrooms.availableRms) + "间") : "有空房"
                                                    }
                                                </div>
                                                <div className="ub">
                                                    {
                                                        activityrooms.discountInfoList.map((info: any) => {
                                                            return <div>{crypto.desDecode(info)}</div>
                                                        })
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        crypto.desDecode(activityrooms.isFull) == "true" && <div className="nomal full">满房</div>
                                                    }
                                                    {
                                                        (crypto.desDecode(activityrooms.isFull) == "false" && crypto.desDecode(activityrooms.Is998RoomActivity) == "1") && <div className="nomal payMoneyFull">
                                                            <div>预订</div>
                                                            <div>需提前一天</div>
                                                        </div>
                                                    }
                                                    {
                                                        (crypto.desDecode(activityrooms.isFull) == "false" && crypto.desDecode(activityrooms.Is998RoomActivity) == "0") && <div className="nomal payMoney" 
                                                            onClick={() => {_postHotelOrder(crypto.desDecode(activityrooms.activityId),crypto.desDecode(activityrooms.id),true)}}>
                                                            <div>预订</div>
                                                            <div>需预付</div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        })
                                    }
                                    {
                                        item.Greencoinrooms && item.Greencoinrooms.roomReplace > 0 && <div className="list-content ub ub-align-center">
                                            <div className="ub ub-align-center">
                                                <img className="mark" src={require("../../../assets/images/hotelDetail/mark.png")} alt="" />
                                                <div>K币换房</div>
                                                {/* <img className="explain" src={require("../../../assets/images/hotelDetail/explain.png")} alt="" title="K币换房" /> */}
                                            </div>
                                            <div className="no">不含早</div>
                                            <div><span className="no"></span><span>{crypto.desDecode(item.Greencoinrooms.roomReplace)}k币</span></div>
                                            <div></div>
                                            <div className="ub"></div>
                                            <div>
                                                <div className="nomal payMoney" 
                                                    onClick={() => {_postHotelOrder(crypto.desDecode(item.Greencoinrooms.ActivityCode),crypto.desDecode(item.Greencoinrooms.roomTypeId),true)}}>
                                                    <div>K币换房</div>
                                                    <div>不赠早</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        })
                    }

                </div>
            </div>
        </div>
    }

    const _hotelInfos = () => {
        return <div className="hotel-infos" ref={hotelDetailRef}>
            <div className="ub">
                <div className="left">酒店介绍</div>
                <div className="right">{hotelStaticData.description}</div>
            </div>
            <div className="ub">
                <div className="left">联系方式</div>
                <div className="right">
                    <div>酒店总机: {hotelStaticData.backPhone || hotelStaticData.phone}</div>
                    {/* <div>酒店传真: 021-61489220</div> */}
                </div>
            </div>
            <div className="ub">
                <div className="left">酒店设施</div>
                <div className="right ub">
                    {
                        JSON.parse(hotelStaticData.NewHotelFacilities).map((item: any,index:number) => {
                            return <div className="facilities ub ub-align-center" title={item.Describe} key={index}>
                                <img src={ require(`../../../assets/images/hotelDetail/facilities/${item.Type}.png`)} alt="" />
                                <span>{item.Name}</span>
                            </div>
                        })
                    }
                </div>
            </div>
            <div className="ub">
                <div className="left">温馨提示</div>
                <div className="right">{(hotelStaticData.specialNoteList != ""  && JSON.parse(hotelStaticData.specialNoteList).map((item:any) => {return item})) || "无"}</div>
            </div>
            <div className="ub">
                <div className="left">促销信息</div>
                <div className="right">{(hotelStaticData.promotions && hotelStaticData.promotions.map((item:any) => {return item.split("|")[0]})) || "无"}</div>
            </div>
            <div className="ub">
                <div className="left">酒店位置</div>
                <div className="right">
                    <div className="location ub">
                        <div className="map">
                            <div className="look-map" onClick={() => {mapRef.current._change(crt)}}>查看地图</div>
                            {_map()}
                        </div>
                        <div className="address">
                            <div>酒店地址：{hotelStaticData.address}</div>
                            {
                                hotelPeriphery.map((item:any,index:number) => {
                                    return <div key={index}>距离{item.MerchantName}<span>{item.distance}</span>公里</div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    const _comment = () => {
        return <Comment ref={commentRef} propsData={{
            hotelCode: hotelId,
        }}/>
    }

    const _dialogue = () => {
        return <Question propsData={{
            hotelCode: hotelId,
        }}/>
    }

    const _history = () => {
        return <History />
    }

    const _merchant = () => {
        return <Merchant ref={merchantRef} propsData={{
            hotelCode: hotelId,
            longitude: crt.longitude,
            latitude: crt.latitude,
        }}/>
    }

    const _hotelCenter = () => {
        return <div className="hotel-center ub ub-space-between">
            <div className="center-left">
                {_hotelInfos()}
                {_comment()}
                {_dialogue()}
            </div>
            <div className="center-right">
                {_history()}
                {_merchant()}
            </div>
        </div>
    }

    const _renderMapModal = () => {
        return <MapModal ref={mapRef}/>
    }

    const _renderImageList = () => {
        return <ImageList ref={imageListRef}/>
    }

    const _renderRoomDetail = () => {
        return <RoomDetail ref={roomDetailRef}/>
    }

    const _renderAppDownload = () => {
        return <AppDownload ref={appDownloadRef}/>
    }
    

    return (
        <React.Fragment>
            <div className="hotelDetail">
                <div className="detailContent max-width">
                    {_detailHeader()}
                    {_detailTabs()}
                    {_detailRoom()}
                    {_hotelCenter()}
                    {_renderMapModal()}
                    {_renderImageList()}
                    {_renderRoomDetail()}
                    {_renderAppDownload()}
                </div>
            </div>
        </React.Fragment>
    )
}
export default forwardRef(HotelDetail);