"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(str, option) {
    var returnTip = function (type, message) { return { type: type, message: message }; };
    if (!str.trim() && !option.allowEmpty) {
        if (option.show)
            return returnTip("empty", "请填写" + option.show);
        else
            return returnTip("empty", "数据不可为空");
    }
    else {
        if (option.lengthLimit) {
            if (str.replace(/[^\x00-\xff]/g, "**").length > option.lengthLimit) {
                if (option.show)
                    return returnTip("overLimit", option.show + "\u4E0D\u80FD\u8D85\u8FC7" + option.lengthLimit + "\u5B57\u8282");
                else
                    return returnTip("overLimit", "\u4E0D\u80FD\u8D85\u8FC7" + option.lengthLimit + "\u5B57\u8282");
            }
        }
        else {
            switch (option.type) {
                case "phone":
                    if (!/^1[0-9]\d{9}$/.test(str)) {
                        return returnTip("phone", "请填写正确的手机号");
                    }
                    break;
                case "truePhone":
                    if (!/^1[0-9]\d{9}$/.test(str)) {
                        return returnTip("truePhone", "请填写正确的手机号");
                    }
                    break;
                case "qq":
                    if (!/^\d{5,15}$/.test(str)) {
                        return returnTip("qq", "请填写正确的QQ号");
                    }
                    break;
                case "qqGroup":
                    if (!/^\d{5,15}$/.test(str)) {
                        return returnTip("qqGroup", "请填写正确的QQ群号");
                    }
                    break;
            }
        }
    }
    return true;
}
exports.default = default_1;
