import React, { useState, useEffect, useRef, useCallback, useImperativeHandle, forwardRef } from 'react';

import {
    Rate
} from 'antd';

import { useHistory } from 'react-router-dom'

import { cookie, getQueryString } from 'approach/base';

function History(props: any, ref: any) {
    
    const history = useHistory();

    const [ hotelStoryList, setHotelStoryList ] = useState(JSON.parse(cookie.get("hotelStory") || "[]"));

    useEffect(() => {

    }, [])

    //父组件调用子组件的方法 放在useImperativeHandle里面 可以写多个函数
    useImperativeHandle(ref, () => {
        return {
            _change(data: any) {

            },
        };
    });

    const _history = () => {
        return <div className="lists-history">
            <div className="title cursor-pointer">您浏览过的酒店<img src={require("../../../../assets/images/clear.png")} alt="" onClick={() => {cookie.delete("hotelStory");setHotelStoryList([])}}/></div>
            {
                 hotelStoryList.map((item:any,index:number) => {
                     return <div className="history-list ub" key={index}
                     onClick={() => {history.push(`/hotelDetail?hotelId=${item.hotelId}&price=${item.price}`);window.location.reload();}} 
                    >
                        <div className="history-left">
                            <img src={item.img || require("../../../../assets/images/hotelList/noImage.png")} alt="" />
                        </div>
                        <div className="history-right">
                            <div className="history-name cut-2">{item.name}</div>
                                <div className="history-score ub ub-space-between ub-align-center">
                                    <div className="history-star"><Rate disabled allowHalf value={Number(item.totalScore)} style={{ fontSize: 16, color: "#FFD51A" }} /></div>
                                    <div className="history-money"><span>¥{item.price}</span>起</div>
                                </div>
                        </div>
                    </div>
                 })
            }
        </div>
    }

    return (
        <React.Fragment>
            {_history()}
        </React.Fragment>
    )
}
export default forwardRef(History);