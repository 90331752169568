import React, { Component } from 'react';
import './App.scss';

import Home from './pages/Home/index';
import HotelList from './pages/HotelList/index';
import HotelDetail from './pages/HotelDetail/index';

import { cookie, getQueryString } from './approach/base';

import { HashRouter, Switch, Route, Link } from 'react-router-dom';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    ContainerOutlined,
} from '@ant-design/icons';

import { Layout, Button, Menu, Modal, message } from 'antd';
const { SubMenu } = Menu;

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        React.$Store.subscribe(() => {
            // 使用setState中只放一个空对象会更新所有的数据
            // 目的就是触发render的执行, 来重新渲染页面, 让页面的数据发生改变
        })
        this._init();
    }

    _init = () => {
        window.$axios.initRequest.setEnv(null, { "Authorization": "Bearer " + cookie.get("access_token"), "x-flow-id": getQueryString("flowId")?getQueryString("flowId"):cookie.get("flowId") });
    }

    render() {
        // console.log(JSON.parse(JSON.stringify(React.$Store.getState())))
        return (
            <div
                className="router-content"
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    backgroundColor: 'white',
                    overflowY: 'auto'
                }}
            >
                <HashRouter>
                    <Switch>
                        <Route exact path="/" component={HotelList} />
                        <Route exact path="/hotelList" component={HotelList} />
                        <Route exact path="/hotelDetail" component={HotelDetail} />
                    </Switch>
                </HashRouter>
            </div>
        );
    }
}
