import React, { useState, useEffect, useRef, useCallback } from 'react';
import './index.scss';

import {
    Card, Col, Row
} from 'antd';

import { useHistory } from 'react-router-dom'

function Home(props: any, ref: any) {
 
    const history = useHistory();

    return (
        <div className="home">
            home
        </div>
    )
}
export default Home;