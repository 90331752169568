"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    set: function (key, val, time, domain) {
        if (domain === void 0) { domain = 'dianjingquan.cn'; }
        var date = new Date(); //获取当前时间
        var expiresDays = time ? time : 1; //将date设置为n天以后的时间
        date.setTime(date.getTime() + expiresDays * 24 * 3600 * 1000); //格式化为cookie识别的时间
        document.cookie = key + "=" + val + ";expires=" + date.toUTCString() + "domain=" + domain + ";path=/"; //设置cookie
    },
    get: function (key) {
        /*获取cookie参数*/
        var arr, reg = new RegExp("(^| )" + key + "=([^;]*)(;|$)");
        if (arr = document.cookie.match(reg)) {
            return unescape(arr[2]);
        }
        else {
            return null;
        }
    },
    delete: function (key) {
        var date = new Date(); //获取当前时间
        date.setTime(date.getTime() - 10000); //将date设置为过去的时间
        document.cookie = key + "=v; expires =" + date.toUTCString(); //设置cookie
    }
};
