"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * @description 获取字节数
 * @param str  接收字符串
 * @returns 返回字节数
 */
function time(str,separator1 = "-",separator2 = ":") {
    if (str) {
        let newDate = new Date(str);
        let Year = newDate.getFullYear();
        let Month = (newDate.getMonth()+1)>9?(newDate.getMonth()+1):("0"+(newDate.getMonth()+1));
        let Day = newDate.getDate()>9?newDate.getDate():("0"+newDate.getDate());
        let Hours = newDate.getHours()>9?newDate.getHours():("0"+newDate.getHours());
        let Minutes = newDate.getMinutes()>9?newDate.getMinutes():("0"+newDate.getMinutes());
        let Seconds = newDate.getSeconds()>9?newDate.getSeconds():("0"+newDate.getSeconds());
        let newTime = Year+separator1+Month+separator1+Day+" "+Hours+separator2+Minutes+separator2+Seconds;
        return newTime;
    }else {
        return "";
    }
}
;
exports.default = time;
