

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });

var CryptoJS = require("crypto-js");
var tripledes = require("crypto-js/tripledes");
var ecb = require("crypto-js/mode-ecb");
var pkcs7 = require("crypto-js/pad-pkcs7");
var utf8 = require("crypto-js/enc-utf8");
var base64 = require("crypto-js/enc-base64");


const Crypto = {
  desEncode:function(value,key = "GTI20141"){
    let keyHex  = utf8.parse(key);
    return tripledes.encrypt(value, keyHex,{
      mode:ecb,
      padding:pkcs7
    }).toString();
  },
  desDecode:function(value,key = "GTI20141"){
    let keyHex  = utf8.parse(key);
    return tripledes.decrypt({ciphertext:base64.parse(value)},keyHex,{
      mode:ecb,
      padding:pkcs7
    }).toString(utf8);
  },
  encode: function(value,key = "ashopkeyaaaaaaaa"){
    let keyHex = CryptoJS.enc.Utf8.parse(key);
    let valueHex = CryptoJS.enc.Utf8.parse(value); 
    return CryptoJS.AES.encrypt(valueHex, keyHex,{
        mode:ecb,
        padding:pkcs7
    }).toString();
  }
};

exports.default = Crypto;

