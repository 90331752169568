import React, { useState, useEffect, useRef, useCallback, useImperativeHandle, forwardRef } from 'react';
import './index.scss';

import {
    DatePicker, Tabs, Popover, Select, Input, message
} from 'antd';

import debounce from 'lodash/debounce';

import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/date-picker/locale/zh_CN';

import { useHistory } from 'react-router-dom'
import { forIn } from 'lodash';

import { cookie, getQueryString } from '../../approach/base';

const { TabPane } = Tabs;

function Search(props: any, ref: any) {
    
    const [ searchAuto, setSearchAuto ] = useState(false);
    const [ keyCity, setKeyCity ] = useState("上海市");
    const [ showCity, setShowCity ] = useState(0);
    
    const [ keySearch, setKeySearch ] = useState("");
    const [ showKeySearch, setShowKeySearch ] = useState(0);

    const [ startTime, setStartTime ] = useState("");
    const [ endTime, setEndTime ] = useState("");

    const [ keySearchList, setKeySearchList ] = useState({
        currentCityModel:{
            hotelSearchList:[],
            modelSearchList:[]
        }
    });
    const [ newHotelList, setNewHotelList ] = useState([]);

    const [ cityList, setCityList ] = useState<{
        China:{
            CityList:any[]
        },
        OverSeas:any[]
    }>({
        China:{CityList:[]},
        OverSeas:[]
    });

    const [ formData, setFormData ] = useState<{
        cityName: string,//城市name
        cityId: string,//城市ID
        checkintime: string,//到店日期
        checkouttime: string,//离店日期
        days: number,//入住天数
        keyValue: string,//关键字
    }>({
        cityName: "",
        cityId: "",
        checkintime: "",
        checkouttime: "",
        days: 1,
        keyValue: "",
    })

    const history = useHistory();

    const { getCityList, getKeySearch, getNewHotelList } = window.$axios.hotelList;

    useEffect(() => {
        document.addEventListener('click', (e:any) => {
            _body(e);
        });
        _getInit();
        setKeyCity(getQueryString("cityName") || formData.cityName)
        setStartTime(_getTime(new Date().getTime()));
        setEndTime(_getTime(new Date(new Date().getTime() + 24*60*60*1000).getTime()));
        setFormData({...formData,
            ...{checkintime:_getTime(new Date().getTime()),
            cityName: getQueryString("cityName") || formData.cityName,
            cityId: getQueryString("cityId") || formData.cityId,}
        });
    }, [])

    useEffect(() => {
        document.addEventListener('click', (e:any) => {
            _body(e);
        });
    }, [formData])

    //父组件调用子组件的方法 放在useImperativeHandle里面 可以写多个函数
    useImperativeHandle(ref, () => {
        return {
            _change(data: any) {
                setFormData({...formData,...data});
            },
        };
    });

    const _body = (e:any) => {
        if(e.target && e.target.className == 'ant-tabs-tab-btn'){
            return;
        }
        setShowCity(0);
        setKeyCity(formData.cityName);
        setShowKeySearch(0);
    }
 
    const _getInit = async() => {
        await getCityList().then((res:any) => {
            if(res.data){
                setCityList(res.data);
            }
        }).catch((err:any) => {

        })
        await getNewHotelList({pagesize:1,pageindex:0}).then((res:any) => {
            if(res.data){
                setNewHotelList(res.data.items);
            }
        }).catch((err:any) => {

        })
    }

    const _getKeySearch = debounce((key:string) => {
        if(key.length == 0){
            setKeySearchList({
                currentCityModel:{
                    hotelSearchList:[],
                    modelSearchList:[]
                }
            });
            return
        }
        if (formData.cityId == "") {
            message.error("请选择城市!");
            return
        }
        getKeySearch({
            cityId:formData.cityId,
            keyValue:key
        }).then((res:any) => {
            if(res.data){
                setKeySearchList(res.data);
            }else{
                message.error(res.msg);
            }
        }).catch((err:any) => {
            
        })
    },1000)

    const _localStorage = (item:any) => {
        let historyCity = JSON.parse(cookie.get("historyCity") || "[]");

        if (historyCity.map((e:any) => e.cityId).includes(item.cityId)) {
            return 
        }
        historyCity.push(item);
        cookie.set("historyCity", JSON.stringify(historyCity), 30);
    }

    const _getTime = (time:number) => {
        let newTime = new Date(time);
        let month = (newTime.getMonth() + 1)>9?(newTime.getMonth() + 1):"0" + (newTime.getMonth() + 1);
        let day = newTime.getDate()>9?newTime.getDate():"0" + newTime.getDate();
        return newTime.getFullYear() + "-" + month + "-" + day;
    }

    const _toHotelList = () => {
        if(formData.cityId == ""){
            message.error("请选择城市!");
            return
        }
        history.push(`/hotelList?cityName=${encodeURIComponent(formData.cityName)}&cityId=${formData.cityId}&checkintime=${formData.checkintime}&checkouttime=${formData.checkouttime}&days=${formData.days}&keyValue=${formData.keyValue}`);
    }

    return (
        <React.Fragment>
            <div className="search">
                <div className="search-top">
                    <div className="search-content ub ub-space-between max-width">
                        <div className="search-left ub">
                            <div>
                                <div className="color999">目的地</div>
                                <div>
                                    <Input value={keyCity} placeholder="请选择城市" 
                                        onChange={(e) => {
                                            setShowCity(2);
                                            setKeyCity(e.target.value);
                                        }}
                                        onClick={(e) => {
                                            setShowCity(1);
                                            e.nativeEvent.stopImmediatePropagation();
                                        }}
                                        onBlur={() => {
                                            // setShowCity(0);
                                            // setKeyCity(formData.cityName);
                                        }}
                                    />
                                </div>
                                {
                                    showCity == 2 && <div className="cityList" onClick={(e) => {e.nativeEvent.stopImmediatePropagation();}}>
                                        {
                                            cityList.China.CityList.filter(e => (e.Name.indexOf(keyCity)>-1 || e.FullSpell.indexOf(keyCity)>-1)).map(item => {
                                                return <div className="text" onClick={() => {
                                                    setKeyCity(item.Name);
                                                    setFormData({...formData,...{cityId:item.ID,cityName:item.Name}});
                                                    setShowCity(0);
                                                    _localStorage({cityId:item.ID,cityName:item.Name});
                                                }}><span>{item.CityAreaName.split(",")[0]}</span><span>{item.CityAreaName.split(",")[1]}</span></div>
                                            })
                                        }
                                        {
                                            cityList.OverSeas.map(Item => {
                                                return Item.CityList.filter((e:any) => (e.Name.indexOf(keyCity)>-1 || e.FullSpell.indexOf(keyCity)>-1)).map((item:any) => {
                                                    return <div className="text" onClick={() => {
                                                        setKeyCity(item.Name);
                                                        setFormData({...formData,...{cityId:item.ID,cityName:item.Name}});
                                                        setShowCity(0);
                                                        _localStorage({cityId:item.ID,cityName:item.Name});
                                                    }}><span>{item.Name}</span><span>{Item.Name}</span></div>
                                                })
                                            })
                                        }
                                    </div>
                                }
                                {
                                    showCity == 1 && <div className="search-city" onClick={(e) => {e.nativeEvent.stopImmediatePropagation();}}>
                                        <div>请选择城市或输入城市名称</div>
                                        <div>
                                            <div className="history">
                                                <div>搜索历史</div>
                                                <div>
                                                    <div className="screen-type-list ub">
                                                        {
                                                            JSON.parse(cookie.get("historyCity") || "[]").map((item:any) => {
                                                                return <div className="text" onClick={() => {
                                                                    setKeyCity(item.cityName);
                                                                    setFormData({...formData,...{cityId:item.cityId,cityName:item.cityName}});
                                                                    setShowCity(0);
                                                                    _localStorage({cityId:item.cityId,cityName:item.cityName});
                                                                }}>{item.cityName}</div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                            <Tabs defaultActiveKey="0">
                                                <TabPane tab={"国内城市"} key="0">
                                                    <Tabs defaultActiveKey="a0">
                                                        {
                                                            ["热门城市","ABCD","EFGH","IJKL","MNOP","QRST","UVWX","YZ"].map((item,index) => {
                                                                return <TabPane tab={item} key={"a"+index}>
                                                                    <div className="screen-type-list ub">
                                                                        {
                                                                            item == "热门城市"?
                                                                            cityList.China.CityList.filter(e => e.IsHotCity == 1).map((city:any) => {
                                                                                return <div className="text" onClick={() => {
                                                                                    setKeyCity(city.Name);
                                                                                    setFormData({...formData,...{cityId:city.ID,cityName:city.Name}});
                                                                                    setShowCity(0);
                                                                                    _localStorage({cityId:city.ID,cityName:city.Name});
                                                                                }}>{city.Name}</div>
                                                                            }):
                                                                            cityList.China.CityList.filter(e => item.split("").includes(e.ShortName.substring(0,1))).map((city:any) => {
                                                                                return <div className="text" onClick={() => {
                                                                                    setKeyCity(city.Name);
                                                                                    setFormData({...formData,...{cityId:city.ID,cityName:city.Name}});
                                                                                    setShowCity(0);
                                                                                    _localStorage({cityId:city.ID,cityName:city.Name});
                                                                                }}>{city.Name}</div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                </TabPane> 
                                                            })
                                                        }
                                                    </Tabs>
                                                </TabPane> 
                                                <TabPane tab={"国外城市"} key="1">
                                                    <Tabs defaultActiveKey="b0">
                                                        {
                                                            cityList.OverSeas.map((item,index) => {
                                                                return <TabPane tab={item.Name} key={"b"+index}>
                                                                    <div className="screen-type-list ub">
                                                                        {
                                                                            item.CityList.map((city:any) => {
                                                                                return <div className="text" onClick={() => {
                                                                                    setKeyCity(city.Name);
                                                                                    setFormData({...formData,...{cityId:city.ID,cityName:city.Name}});
                                                                                    setShowCity(0);
                                                                                    _localStorage({cityId:city.ID,cityName:city.Name});
                                                                                }}>{city.Name}</div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                    
                                                                </TabPane> 
                                                            })
                                                        }
                                                    </Tabs>
                                                </TabPane> 
                                            </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                }
                                
                            </div>
                            <div>
                                <div className="color999">入住</div>
                                <div onClick={(e) => {e.nativeEvent.stopImmediatePropagation();}}>
                                    <DatePicker
                                        className="datePicker"
                                        locale={locale}
                                        value={moment(startTime)}
                                        disabledDate={(current:any) => {
                                            return current < moment().subtract(1, 'days');  
                                        }}
                                        onChange={(date,dateString) => {
                                            setStartTime(dateString);
                                            if(moment(dateString).valueOf() >= moment(endTime).valueOf()){
                                                setEndTime(_getTime(moment(dateString).valueOf() + 24*60*60*1000));
                                            }
                                            setFormData({...formData,...{
                                                checkintime:dateString,
                                                checkouttime:_getTime(moment(dateString).valueOf() + 24*60*60*1000),
                                                days: (new Date(_getTime(moment(dateString).valueOf() + 24*60*60*1000)).getTime() - new Date(dateString).getTime())/(24*60*60*1000)
                                            }});
                                        }}
                                        format="YYYY-MM-DD"
                                        bordered={false}
                                    />
                                </div>
                            </div>
                            <div>-{(new Date(endTime).getTime() - new Date(startTime).getTime())/(24*60*60*1000)}晚 -</div>
                            <div>
                                <div className="color999">离店</div>
                                <div onClick={(e) => {e.nativeEvent.stopImmediatePropagation();}}>
                                    <DatePicker
                                        className="datePicker"
                                        locale={locale}
                                        value={moment(endTime)}
                                        disabledDate={(current:any) => {
                                            return current.valueOf() <= moment(startTime).valueOf(); 
                                        }}
                                        onChange={(date,dateString) => {
                                            setEndTime(dateString);
                                            setFormData({...formData,...{
                                                days: (new Date(dateString).getTime() - new Date(startTime).getTime())/(24*60*60*1000),
                                                checkouttime: dateString
                                            }});
                                        }}
                                        format="YYYY-MM-DD"
                                        bordered={false}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="color999">关键字选填</div>
                                <div>
                                    <Input value={formData.keyValue} placeholder="请输入酒店名/品牌/地标" 
                                        onChange={(e) => {
                                            setShowKeySearch(2);
                                            _getKeySearch(e.target.value);
                                            setFormData({...formData,...{keyValue:e.target.value}});
                                        }} 
                                        onClick={(e) => {
                                            setShowKeySearch(1);
                                            e.nativeEvent.stopImmediatePropagation();
                                        }}
                                        onBlur={() => {
                                            // setShowKeySearch(0);
                                        }}
                                    />
                                </div>
                                {
                                    showKeySearch == 2 && <div className="cityList" onClick={(e) => {e.nativeEvent.stopImmediatePropagation();}}>
                                        {
                                            keySearchList.currentCityModel.hotelSearchList.map((item:any) => {
                                                return <div className="text ub ub-space-between" onClick={() => {history.push(`/hotelDetail?hotelId=${item.hotelCode}&price=${item.price}`);window.location.reload();}}><span style={{width: "80%"}}>{item.hotelName}</span><span>酒店</span></div>
                                            })
                                        }
                                        {
                                            keySearchList.currentCityModel.modelSearchList.map((item:any) => {
                                                return <div className="text ub ub-space-between" onClick={() => {
                                                    setFormData({...formData,...{keyValue:item.businessName}});
                                                    setShowKeySearch(0);
                                                }}><span style={{width: "80%"}}>{item.businessName}</span><span>地标</span></div>
                                            })
                                        }
                                    </div>
                                }
                                {
                                    showKeySearch == 1 && <div className="cityList" onClick={(e) => {e.nativeEvent.stopImmediatePropagation();}}>
                                        <div className="text ub ub-space-between" style={{fontWeight:600}}>新店特惠</div>
                                        {
                                            newHotelList.map((item:any) => {
                                                return <div className="text ub ub-space-between" onClick={() => {history.push(`/hotelDetail?hotelId=${item.id}&price=${item.price}`);window.location.reload();}}><span style={{width: "80%"}}>{item.name}</span><span>酒店</span></div>
                                            })
                                        }
                                    </div>
                                }
                            </div>
                            <div onClick={() => _toHotelList()}>搜索</div>
                        </div>
                        <div className="search-right" onClick={() => {
                            window.open(`https://998.com/HotelMap?cityId=${formData.cityId}&cityText=${formData.cityName}`)
                        }}><img src={require("../../assets/images/map.png")} alt="" />地图预订</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default forwardRef(Search);