"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var axios_1 = require("axios");
var jsencrypt_1 = require("jsencrypt");
var base_1 = require("../../approach/base");
var errorConfig_1 = require("./errorConfig");
var encrypt = new jsencrypt_1.JSEncrypt();
encrypt.setPublicKey("-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDFsRGbGzAGX60J4GI4mYBXWij47/QFcqP/9bO2tmzj982EpEBhojR4F65ueJ7+dmpBLJaafAnA7cAB48SRhAkO6JH4RDUxGIBTDYfy0chsuVjbZypmOf0bROdS1wOi7MDNGzc3mTbxGaw6OUWwVZ+HR2oxEgDHDMpOliUIiZeDeQIDAQAB-----END PUBLIC KEY-----");
var default_1 = /** @class */ (function () {
    function default_1(options) {
        var _this = this;
        this.env = {};
        this.axios = null;
        this.envHeaders = {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Token": null,
            "access_token": null
        };
        this.isGetTime = true;
        this.timeDiff = 0;
        this.setEnv = function (env, headers) {
            if (headers === void 0) { headers = {}; }
            if (env)
                _this.env = env;
            Object.assign(_this.envHeaders, headers);
            _this.axios = axios_1.default.create({
                baseURL: _this.env.REACT_APP_BASE_URL,
                headers: _this.envHeaders
            });
        };
        this.middleRequest = function (object) {
            if (_this.isGetTime) {
                return _this.request(Object.assign({}, object, {
                    // headers: {
                    //     ant_key: encrypt.encrypt("DJQ!" + (new Date().getTime() + _this.timeDiff))
                    // }
                }));
            }
            else {
                return new Promise(function (resolve, reject) {
                    _this.request({
                        url: "/base/time",
                        method: 'get',
                        params: {}
                    }).then(function (result) {
                        var timeStamp = result.server_current_time;
                        _this.timeDiff = timeStamp - new Date().getTime();
                        _this.isGetTime = true;
                        _this.middleRequest(object).then(resolve, reject);
                    });
                });
            }
        };
        this.get = function (url, params, headers) {
            if (url === void 0) { url = ''; }
            if (params === void 0) { params = {}; }
            if (headers === void 0) { headers = {}; }
            return _this.middleRequest({
                url: url,
                method: 'get',
                params: params,
                headers: headers
            });
        };
        this.post = function (url, params, headers) {
            if (url === void 0) { url = ''; }
            if (params === void 0) { params = {}; }
            if (headers === void 0) { headers = {}; }
            return _this.middleRequest({
                method: 'post',
                url: url,
                data: params,
                headers: headers
            });
        };
        this.request = function (data) {
            return new Promise(function (resolve, reject) {
                _this.axios(data).then(function (result) {
                    if (result.status === 200) {
                        resolve(result.data);
                    }
                    else {
                        reject(result.status);
                    }
                }).catch(function (error) {
                    if (error.response) {
                        try {
                            var _a = error.response, status_1 = _a.status, errorno = _a.data.errorno;
                            if (status_1 === 401) {
                                base_1.cookie.delete("loginToken");
                                base_1.cookie.delete("loginUid");
                                base_1.cookie.delete("access_token");
                                base_1.cookie.delete("userId");
                                base_1.cookie.delete("userInfo");
                                alert('登录过期，请重新登录!');
                                window.location.reload();
                            }
                            if(status_1 === 403){
                                alert('您没有权限');
                            }
                            else {
                                if (errorConfig_1.default[errorno]) {
                                    reject(Object.assign({}, error.response, {
                                        errorno: errorno,
                                        errMess: errorConfig_1.default[errorno]
                                    }));
                                }
                                else {
                                    reject(Object.assign({}, error.response, {
                                        errorno: errorno
                                    }));
                                }
                            }
                        }
                        catch (e) {
                            reject(error.response);
                        }
                    }
                    else {
                        reject({ type: 'error' });
                    }
                });
            });
        };
        this.upload = function (form, option, url) {
            var formData = new FormData();
            // formData.append("key", option.dir);
            // formData.append("policy", option.policy);
            // formData.append("OSSAccessKeyId", option.accessid);
            // formData.append("success_action_status", '200');
            // formData.append("Cache-Control", 'no-cache');
            // if (option.callback)
            //     formData.append("callback", option.callback);
            // if (option.verify)
            //     formData.append("x:verify", option.verify);
            // formData.append("signature", option.signature);
            formData.append("file", form);
            return new Promise(function (resolve, reject) {
                axios_1.default({
                    url: _this.env.REACT_APP_IMAGE_URL,
                    method: 'post',
                    data: formData,
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                .then(function (result) {
                    if (result.status === 200) {
                        resolve(result.data);
                    }
                    else {
                        reject(result);
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
            });
        };
        var env = options.env, headers = options.headers;
        this.setEnv(env, headers);
    }
    return default_1;
}());
exports.default = default_1;
;
