import React, { useState, useEffect, useRef, useCallback } from 'react';
import './index.scss';

import {
    Popover, Button
} from 'antd';

import { cookie } from '../../approach/base';

import { useHistory } from 'react-router-dom'

function Header(props: any, ref: any) {

    const [ activedId, setActivedId ] = useState(1);
 
    const history = useHistory();

    return (
        <div className="header">
            <div className="header-top color999 ub ub-space-between max-width">
                <div className="header-top-left"><img src={require("../../assets/images/tel.png")} alt="" /></div>
                <div className="header-top-right ub">
                    <Popover placement="bottom" content={
                        <div className="header-img">
                            <img src={require("../../assets/images/wxTotast.png")} alt="" />
                        </div>
                    }>
                        <div><img src={require("../../assets/images/wx.png")} alt="" /></div>
                    </Popover>
                    <Popover placement="bottom" content={
                        <div className="header-img">
                            <img src={require("../../assets/images/wxXtotast.png")} alt="" />
                        </div>
                    }>
                        <div><img src={require("../../assets/images/wx_x.png")} alt="" /></div>
                    </Popover>
                    <Popover placement="bottom" content={
                        <div className="header-img">
                            <img src={require("../../assets/images/APPDownload.png")} alt="" />
                            <a className="iosDownload" href="https://apps.apple.com/cn/app/id656170799" target="_blank" rel="noopener noreferrer"></a>
                            <a className="androidDownload" href="https://sj.qq.com/myapp/detail.htm?apkName=com.greentree.android" target="_blank" rel="noopener noreferrer"></a>
                        </div>
                    }>
                        <div><img src={require("../../assets/images/phone.png")} alt="" /></div>
                    </Popover>
                    <a className="header-help" href="https://998.com/Help" target="_blank">帮助中心</a>
                    {
                        cookie.get("userInfo")?
                        <a className="header-name" href="https://998.com/UserCenter">{JSON.parse(decodeURIComponent(`${cookie.get("userInfo")}`)).Name}</a>
                        :
                        <React.Fragment>
                            <a className="header-login" href="https://998.com/Account/Sign">登录</a>
                            <a className="header-register" href="https://998.com/UserRegister/Signin">注册</a>
                        </React.Fragment>
                    }
                </div>
            </div>
            <div className="header-center">
                <div className="header-content ub ub-space-between ub-align-center max-width">
                    <div className="header-logo"
                    onClick={() => {
                        window.open("https://998.com/")
                    }}><img src={require("../../assets/images/logo.png")} alt="" /></div>
                    <div className="header-tabs colorfff ub">
                        <div className={`header-tab ${activedId == 1?"actived":""}`} onClick={() => {
                            setActivedId(1);
                            history.push("/hotelList")
                            }}>酒店预订</div>
                        <a className={`header-tab ${activedId == 2?"actived":""}`} onClick={() => {setActivedId(2)}} href="https://998.com/Information/defaultu">关于我们</a>
                        <Popover placement="bottom" content={
                            <div className="header-lists">
                                <div className="brand-list ub">
                                    <div className="brandType">格林酒店</div>
                                    <div className="brand">
                                        <a href="https://998.com/shtml/40/zh/index.html">格林东方</a>
                                        <a href="https://998.com/shtml/20/zh/index.html">格美</a>
                                        <a href="https://998.com/shtml/21/zh/index.html">格雅</a>
                                        <a href="https://998.com/shtml/22/zh/index.html">格菲</a>
                                        <a href="https://998.com/shtml/00/zh/index.html">格林豪泰</a>
                                        <a href="https://998.com/shtml/30/zh/index.html">格盟</a>
                                        <a href="https://998.com/shtml/23/zh/index.html">无眠</a>
                                        <a href="https://998.com/shtml/50/zh/index.html">青皮树</a>
                                        <a href="https://998.com/shtml/70/zh/index.html">贝壳</a>
                                        <a href="https://998.com/shtml/80/zh/index.html">格林客栈</a>
                                        <a href="https://998.com/shtml/81/zh/index.html">格林公寓</a>
                                        <a href="https://998.com/shtml/82/zh/index.html">格林魔都公寓</a>
                                    </div>
                                </div>
                                {/* <div className="brand-list ub">
                                    <div className="brandType">雅阁酒店</div>
                                    <div className="brand">
                                        <a href="http://www.argylehotels.com/index.php?m=content&amp;c=index&amp;a=show&amp;catid=213&amp;id=1">雅阁大酒店</a>
                                        <a href="http://www.argylehotels.com/index.php?m=content&amp;c=index&amp;a=show&amp;catid=213&amp;id=5">雅阁璞邸酒店</a>
                                        <a href="http://www.argylehotels.com/index.php?m=content&amp;c=index&amp;a=show&amp;catid=213&amp;id=2">雅阁度假酒店</a>
                                        <a href="http://www.argylehotels.com/index.php?m=content&amp;c=index&amp;a=show&amp;catid=213&amp;id=3">雅阁酒店</a>
                                        <a href="http://www.argylehotels.com/index.php?m=content&amp;c=index&amp;a=show&amp;catid=213&amp;id=7">雅阁公寓</a>
                                        <a href="http://www.argylehotels.com/index.php?m=content&amp;c=index&amp;a=show&amp;catid=213&amp;id=4">雅阁奥斯特酒店</a>
                                        <a href="http://www.argylehotels.com/index.php?m=content&amp;c=index&amp;a=show&amp;catid=213&amp;id=4">奥斯特精选酒店</a>
                                        <a href="http://www.argylehotels.com/index.php?m=content&amp;c=index&amp;a=show&amp;catid=213&amp;id=4">奥斯特公寓</a>
                                    </div>
                                </div> */}
                            </div>
                        }>
                            <div className={`header-tab ${activedId == 3?"actived":""}`} onClick={() => {setActivedId(3)}}>我们的品牌</div>
                        </Popover>
                        <Popover placement="bottom" content={
                            <div className="header-lists">
                                <div className="tother-list">
                                    <a href="https://998.com/ColorfulMember" target="_blank">成为会员</a>
                                </div>
                                <div className="tother-list">
                                    <a href="https://998.com/Help/list?typeId=ff8db4d3-4a3f-41f0-9574-c6ed036554af" target="_blank">会员手册</a>
                                </div>
                            </div>
                        }>
                            <div className={`header-tab ${activedId == 4?"actived":""}`} onClick={() => {setActivedId(4)}}>格美会</div>
                        </Popover>
                        <a className={`header-tab ${activedId == 5?"actived":""}`} onClick={() => {setActivedId(5)}} href="https://www.168.com/index.html" target="_blank">168商城</a>
                        <Popover placement="bottom" content={
                            <div className="header-lists">
                                <div className="tother-list">
                                    <a href="https://998.com/NewsCenter" target="_blank">新闻中心</a>
                                </div>
                                <div className="tother-list">
                                    <a href="https://998.com/Forum" target="_blank">论坛社区</a>
                                </div>
                            </div>
                        }>
                            <div className={`header-tab ${activedId == 6?"actived":""}`} onClick={() => {setActivedId(6)}}>新闻中心</div>
                        </Popover>
                        <a className={`header-tab ${activedId == 7?"actived":""}`} onClick={() => {setActivedId(7)}} href="https://998.com/Welfare" target="_blank">社会责任</a>
                        <Popover placement="bottom" content={
                            <div className="header-lists">
                                <div className="join-list">
                                    <a href="https://998.com/Join/EasternJoin" target="_blank">格林东方</a>
                                    <a href="https://998.com/Join/GemJoin" target="_blank">格美</a>
                                    <a href="https://998.com/Join/GyaJoin" target="_blank">格雅</a>
                                    <a href="https://998.com/Join/VXJoin" target="_blank">格菲</a>
                                    <a href="https://998.com/Join/GreenTreeInnJoin" target="_blank">格林豪泰</a>
                                    <a href="https://998.com/Join/AllianceJoin" target="_blank">格盟</a>
                                    <a href="https://998.com/Join/DeepSleepJoin" target="_blank">无眠</a>
                                    <a href="https://998.com/Join/VaticaJoin" target="_blank">青皮树</a>
                                    <a href="https://998.com/Join/ShellJoin" target="_blank">贝壳</a>
                                    <a href="https://998.com/Join/DianjJoin" target="_blank">电竞</a>
                                    <a href="https://998.com/Join/PartmentJoin" target="_blank">格林公寓</a>
                                    <a href="https://998.com/Join/BnBJoin" target="_blank">格林客栈</a>
                                </div>
                            </div>
                        }>
                            <a className={`header-tab ${activedId == 8?"actived":""}`} onClick={() => {setActivedId(8)}} href="http://">加盟</a>
                        </Popover>
                        <a className={`header-tab ${activedId == 9?"actived":""}`} onClick={() => {setActivedId(9)}} href="https://www.greentreeinn.com/" target="_blank">海外酒店</a>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
export default Header;