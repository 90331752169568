import React, { useState, useEffect, useRef, useCallback } from 'react';
import './index.scss';

import {
    Card, Col, Row
} from 'antd';

import { useHistory } from 'react-router-dom'
import Header from '../../components/Header';
import Footer from '../../components/footer';

import Search from './search';
import HotelList from './hotelList';

function HotelListPage(props: any, ref: any) {

    const [ formData, setFormData ] = useState({});

    const searchRef:any = useRef();
    const hotelListRef:any = useRef();

    useEffect(() => {
        _getFilter();
    }, [])
 
    const _getFilter = () => {
        
    }

    const _changeListData = (data:any) => {
        hotelListRef.current._change(data);
    }

    const _changeSearchData = (data:any) => {
        searchRef.current._change(data);
    }

    return (
        <React.Fragment>
            <Header />
            <Search ref={searchRef} changeData={(data:any) => _changeListData(data)}/>
            <HotelList ref={hotelListRef} changeData={(data:any) => _changeSearchData(data)}/>
            <Footer />
        </React.Fragment>
    )
}
export default HotelListPage;