import React, { useState, useEffect, useRef, useCallback, useImperativeHandle, forwardRef } from 'react';

import {
    LeftOutlined,
    RightOutlined
} from '@ant-design/icons';

import {
    Carousel
} from 'antd';

import { cookie } from 'approach/base';

function Question(props: any, ref: any) {
    const [userId, setUserId] = useState(JSON.parse(decodeURIComponent(`${cookie.get("userInfo")}`))?JSON.parse(decodeURIComponent(`${cookie.get("userInfo")}`)).Card_no:"");

    const [ questions, setQuestions ] = useState([]);

    const carouselRef: any = useRef();

    const { getQuestions } = window.$axios.hotelDetail;

    useEffect(() => {
        _getHotelDetail();
    }, [])

    //父组件调用子组件的方法 放在useImperativeHandle里面 可以写多个函数
    useImperativeHandle(ref, () => {
        return {
            _change(data: any) {

            },
        };
    });

    const _getHotelDetail = () => {
        getQuestions({
            hotelCode: props.propsData.hotelCode,
            userId: userId,
            pageSize: 10000,
            pageIndex: 1,
        }).then((res: any) => {
            let arr:any = [];
            res.data.ResponseData.map((item:any,index:number) => {
                let datas = [];
                if (index%2 == 0) {
                    datas.push(item);
                    if (res.data.ResponseData[index+1]) {
                        datas.push(res.data.ResponseData[index+1]);
                    }
                    arr.push({
                        datas:datas
                    })
                }
            })
            setQuestions(arr)
        }).catch((err: any) => {
            console.log(err)
        })
    }

    const _dialogue = () => {
        return <div className="dialogue">
            <div className="title">酒店问答</div>
            <div className="dialogue-lists">
                <div className="carousel">
                    <Carousel
                        // autoplay
                        dots={false}
                        ref={carouselRef}
                    >
                        {
                            questions.map((item:any,index:number) => {
                                return <div key={index}>
                                    <div className="ub">
                                        {
                                            item.datas.map((data:any,dataIndex:number) => {
                                                return <div className="dialogue-list" key={dataIndex}>
                                                    <div className="ub"><div className="question">问</div><div className="content cut-2">{data.Question.ContentDes}</div></div>
                                                    <div className="ub"><div className="answer">答</div><div className="content cut-2">{data.AnswerExtends[0]?.ContentDes}</div></div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </Carousel>
                </div>
                <div className="leftOutlined" onClick={() => { carouselRef.current.prev() }}><LeftOutlined style={{ fontSize: 18, color: "#C6C6C6" }} /></div>
                <div className="rightOutlined" onClick={() => { carouselRef.current.next() }}><RightOutlined style={{ fontSize: 18, color: "#C6C6C6" }} /></div>
            </div>
        </div>
    }


    return (
        <React.Fragment>
            {_dialogue()}
        </React.Fragment>
    )
}
export default forwardRef(Question);