import React, { useState, useEffect, useRef, useCallback, useImperativeHandle, forwardRef } from 'react';
import "./index.scss";

import {
    LeftOutlined,
    RightOutlined
} from '@ant-design/icons';
import {
    Modal,Carousel
} from 'antd';

import { Map, Marker, CustomOverlay } from 'react-bmapgl';
import { identity } from 'lodash';

import { crypto } from 'approach/base';

function RoomDetail(props: any, ref: any) {
    const [ isVisible, setIsVisible ] = useState(false);

    const [ roomName, setRoomName ] = useState("");

    const [ roomDetail, setRoomDetail ] = useState({
        ActivityDesc: "",
        ImageList: "[]",
        RoomInfoDec: "",
        area: "",
        breakfast: "",
        dow: "",
        floor: "",
        healthyMaterials: "",
        network: "",
        other: "",
        personNum: "",
        photoUrl: "",
        roomType: ""
    });

    const [ allPrice, setAllPrice ] = useState({
        digitalCard: "",
        goldCard: "",
        platinumCard: "",
        retailPrice: "",
        shellCard: "",
        vipCard: "",
    });

    const carouselRef: any = useRef();

    const { getHotelDescription } = window.$axios.hotelDetail;

    useEffect(() => {
        // setCrt()
    })

    //父组件调用子组件的方法 放在useImperativeHandle里面 可以写多个函数
    useImperativeHandle(ref, () => {
        return {
            _change(hotelId: any,item: any) {
                setIsVisible(true);
                _getHotelDescription(hotelId,crypto.desDecode(item.id));
                setAllPrice(item.allPrice);
                setRoomName(crypto.desDecode(item.typeName));
            },
        };
    });

    const _getHotelDescription = (hotelId:any,id:any) => {
        getHotelDescription({hotelId:hotelId,roomType:id}).then((res:any) => {
            setRoomDetail(res.data);
        }).catch((err:any) => {
            console.log(err)
        })
    }

    const _dialogue = () => {
        return <div className="dialogue ub">
            <div className="upOutlined" onClick={() => { carouselRef.current.prev() }}><LeftOutlined  style={{ fontSize: 18, color: "#FFF" }} /></div>
            <div className="carousel">
                <Carousel
                    dots={false}
                    ref={carouselRef}
                >
                    {
                        roomDetail.ImageList != "" && JSON.parse(roomDetail.ImageList).map((item:any,index:number) => {
                            return <div key={index}><div className="dialogue-img"><img src={item.bigimg || require("../../../../assets/images/hotelDetail/bg.png")} alt="" /></div></div>
                        })
                    }
                </Carousel>
            </div>
            <div className="downOutlined" onClick={() => { carouselRef.current.next() }}><RightOutlined style={{ fontSize: 18, color: "#FFF" }} /></div>
        </div>
    }

    const _roomDetail = () => {
        return <div className="room-detail ub">
            <div className="left">
                {_dialogue()}
            </div>
            <div className="right">
                <div className="ub"><span>【面积】</span>{roomDetail.area}；</div>
                <div className="ub"><span>【楼层】</span>{roomDetail.floor}</div>
                <div className="ub"><span>【床】</span>{roomDetail.roomType}</div>
                <div className="ub"><span>【窗户】</span>{roomDetail.dow}</div>
                <div className="ub"><span>【其他】</span>
                    <div className="ub">
                        <span>数字卡:  {crypto.desDecode(allPrice.digitalCard)}</span> 
                        <span>贵宾卡:  {crypto.desDecode(allPrice.vipCard)}</span>
                        <span>金卡:  {crypto.desDecode(allPrice.goldCard)}</span>
                        <span>铂金卡:  {crypto.desDecode(allPrice.platinumCard)} </span>
                        <span>贝壳卡:  {crypto.desDecode(allPrice.shellCard)}</span>
                    </div>
                </div>
            </div>
        </div>
    }

    return (
        <React.Fragment>
            <Modal
                 title={roomName}
                 visible={isVisible}
                 footer={null}
                 width={773}
                 onCancel={() => {setIsVisible(false)}}
                 maskClosable={false}
                 destroyOnClose
            >
                {_roomDetail()}
            </Modal>
        </React.Fragment>
    )
}
export default forwardRef(RoomDetail);