"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MD5_1 = require("./MD5"); //md5操作
exports.md5 = MD5_1.default;
var base64_1 = require("./base64"); //md5操作
exports.base64 = base64_1.default;
var cookie_1 = require("./cookie"); //cookie操作
exports.cookie = cookie_1.default;
var getQueryString_1 = require("./getQueryString"); //查询search值
exports.getQueryString = getQueryString_1.default;
var getQueryStringByRouter_1 = require("./getQueryStringByRouter"); //查询hash下的search值
exports.getQueryStringByRouter = getQueryStringByRouter_1.default;
var checkValue_1 = require("./checkValue"); //cookie操作
exports.checkValue = checkValue_1.default;
var getLength_1 = require("./getLength"); // 获取字节长度
exports.getLength = getLength_1.default;
var throttle_1 = require("./throttle"); // 函数节流
exports.throttle = throttle_1.default;
var debounce_1 = require("./debounce"); // 函数防抖
exports.debounce = debounce_1.default;
var time = require("./time"); // utc时间处理
exports.time = time.default;
var crypto = require("./crypto"); // aes加密处理
exports.crypto = crypto.default;
